import { CardModel } from './card.model';

export class UserModel {
    constructor(
        public accessToken: string = '',
        public clientId: string = '',
        public hashid: string = '',
        public email: string = '',
        public addresses: UserAddressModel[] = [],
        public address: string = '',
        public billingAddress: string = '',
        public firstname: string = '',
        public languageIso: string = '',
        public lastname: string = '',
        public phone: string = '',
        public cards: CardModel[] = [],
        public settings: UserSettingsModel = new UserSettingsModel(),
        public ip: string = '',
        public userAgent: string = '',
        public action: ActionModel = new ActionModel(),
        public verified: boolean = false,
        public verifyBefore: string = null,
        public remainingTime: number = 0,
        public fullSignupAnswer: number = 0,
        public hasDebt: boolean = false
    ) {}
}

export class UserSettingsModel {
    constructor(
        public sendOperations: boolean = false,
        public summaryWeek: boolean = false,
        public summaryMonth: boolean = false,
        public reserves: boolean = false,
        public notifications: boolean = false,
        public promotionsApp: boolean = false,
        public promotionsOthers: boolean = false
    ) {}
}

export class ActionModel {
    constructor(
        public type: string = '',
        public value: string = '',
        public token: string = '',
        public client: string = ''
    ) {}
}

export interface UserAddressModel {
    hashid: string;
    companyName: string;
    address: string;
    addressCont: string;
    postalCode: string;
    city: string;
    county: string;
    isBillingAddress: boolean;
}
