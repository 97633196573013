import { NgModule, APP_INITIALIZER, Injectable } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpClientModule, HttpClient, HTTP_INTERCEPTORS, HttpHeaders } from '@angular/common/http';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { StartupService } from '@services/startup.service';
import { HttpInterceptorService } from '@services/http-interceptor.service';
import { Observable } from 'rxjs';
import { environment } from '../../environments/environment';

export function createTranslateLoader(http: HttpClient) {
	return new TranslateHttpLoader(http, './assets/i18n/', `.json?v=${new Date().getTime()}`);
}

@Injectable()
export class CustomTranslateLoader implements TranslateLoader  {
	public cmHeaders:HttpHeaders;
    constructor(private http:HttpClient ) {
		this.cmHeaders = new HttpHeaders({
			'Content-Type': 'application/json'
		});
	}
    getTranslation(lang: string): Observable<any>{
		const apiAddress = `${environment.PATH_API}lang/i18n/${lang}`;
        return new Observable(subscriber => {
			if(environment.production){
				this.http.get(apiAddress,{ headers: this.cmHeaders }).subscribe(
					(res: Response) => {
						subscriber.next(res);
						subscriber.complete();
					},
					error => {
						//failed to retrieve from api, switch to local
						this.http.get(`./assets/i18n/${lang}.json?v=${new Date().getTime()}`).subscribe((res: Response) => {
							subscriber.next(res);
							subscriber.complete();
						})
					}
				);
			}else{
				this.http.get(`./assets/i18n/${lang}.json?v=${new Date().getTime()}`).subscribe((res: Response) => {
					subscriber.next(res);
					subscriber.complete();
				})
			}
        });
    }
}

export function startupServiceFactory(startupService: StartupService): Function {
	return () => startupService.load();
}

@NgModule({
	declarations: [],
	imports: [
		CommonModule,
		HttpClientModule,
        TranslateModule.forRoot({
            loader: {
                provide: TranslateLoader,
                //useFactory: (createTranslateLoader),
				useClass: CustomTranslateLoader,
                deps: [HttpClient]
            }
        })
	],
	providers: [
		{
			provide: HTTP_INTERCEPTORS,
			useClass: HttpInterceptorService,
			multi: true
		},
		{
			provide: APP_INITIALIZER,
			useFactory: startupServiceFactory,
			deps: [StartupService],
			multi: true
        }
	],
	exports:[]
})
export class ConfigModule {}