import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router } from '@angular/router';
import { UserService } from '@services/user.service';

@Injectable({
  	providedIn: 'root'
})
export class AuthGuard implements CanActivate {

	constructor(
        private user: UserService,
        private router: Router
    ) { }

	async canActivate(next: ActivatedRouteSnapshot,state: RouterStateSnapshot) {
		if (this.user.isAuthenticated()) {
            return true;
        } else {
            let _auth = await this.user.checkToken();
            if(_auth){
                return true;
            }else{
                this.router.navigate(['auth'], {replaceUrl:true});
                return false;
            }
        }
	}

}
