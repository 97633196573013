import { Component, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
	selector: 'app-pdf-viewer-modal',
	templateUrl: './pdf-viewer-modal.component.html',
	styleUrls: ['./pdf-viewer-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class PdfViewerModalComponent implements OnInit {

	public titleTx:string = '';
	public base64Src:string = '';
	public src:string = '';
	public showPdf:boolean;

	constructor(
		private modalController: ModalController,
		private navParams: NavParams,
	) {
		this.titleTx = (this.navParams.get('titleTx')) ? this.navParams.get('titleTx') : '';
		this.base64Src = (this.navParams.get('base64Src')) ? this.navParams.get('base64Src') : '';
		this.src = (this.navParams.get('src')) ? this.navParams.get('src') : '';
		this.showPdf = false;
	}

	public closeModal() {
        this.modalController.dismiss();
	}

	ngOnInit() {}

}
