import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { UtilService } from './util.service';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { CardModel } from '@models/card.model';
import { map, catchError, take } from 'rxjs/operators';
import { httpData } from '@models/data.model';

@Injectable({
    providedIn: 'root'
})
export class CardsService {

    public cards: Observable<CardModel[]>;
    private _cards: BehaviorSubject<CardModel[]>;
    private dataStore: {
        cards: CardModel[]
    }

    constructor(
        private api: ApiService,
        private util: UtilService
    ) {
        this.dataStore = {
            cards: null
        };
        this._cards = new BehaviorSubject(null);
        this.cards = this._cards.asObservable();
    }

	public resetCards = () => {
		this.dataStore = {
            cards: null
        };
		this._cards.next(Object.assign({}, this.dataStore).cards);
	}

    public getCards = (dataSend:any = {}): Promise<any> => {
        return this.api.get('cards', dataSend).pipe(
            map((data: httpData) => {
                this.dataStore.cards = data.data;
				this._cards.next(Object.assign({}, this.dataStore).cards);
                return data.data;
            }),
            catchError( error => {
				//return this.util.apiErrorReturn(error);
				return throwError(error);
			})
        ).toPromise()
	}

	public editCard = (dataSend:any = {}): Observable<any> => {
        return this.api.post('cards/edit', dataSend).pipe(
            map((data: httpData) => {
                this.getCards();
                return true;
            }),
            catchError( error => {return this.util.apiErrorReturn(error);})
        )
	}

	public deleteCard = (dataSend:any = {}): Observable<any> => {
        return this.api.post('cards/delete', dataSend).pipe(
            map((data: httpData) => {
                this.getCards();
                return true;
            }),
            catchError( error => {return this.util.apiErrorReturn(error);})
        )
    }
}
