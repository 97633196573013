import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
	name:"formatNumber"
})
export class FormatNumberPipe implements PipeTransform {
	transform(value:string|number, decimal?:number, separator?:string, forceDecimal?:boolean):string {
		let result:string = "";
        try {
            value = value.toString();
            if(value && value.length){
                let _decimal = decimal || 0;
                let separator_decimal = separator || ',';
                let separator_miles = (separator_decimal == ',') ? '.' : ',';
                let elementNumber:any = parseFloat(value);
                if(!isNaN(elementNumber)){
                    elementNumber=elementNumber.toFixed(_decimal);
                    elementNumber=elementNumber.toString().replace(".", separator_decimal);
                    let _forceDecimal = forceDecimal || false;
                    if(typeof(decimal) != 'undefined' && _forceDecimal === true && elementNumber.split(separator_decimal)[1] == '00'){
                        elementNumber = elementNumber.split(separator_decimal)[0];
                    }
                    let miles=new RegExp("(-?[0-9]+)([0-9]{3})");
                    while(miles.test(elementNumber)){
                        elementNumber=elementNumber.replace(miles, "$1" + separator_miles + "$2");
                    }
                    result =  elementNumber;
                }
            }
        } catch (error) {
            result = "";
        }
		return result;
	}
}
