import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'messageChat'
})
export class MessageChatPipe implements PipeTransform {
	transform(value: any, args?:string[]):any {
		let valueReturn = value;
		//valueReturn = valueReturn.replace(/&/g, '&amp;').replace(/</g, '&lt;').replace(/>/g, '&gt;').replace(/"/g, '&quot;'); //htmlentities
		console.log(typeof(args));
		if(typeof(args) != 'undefined'){
			if(args.indexOf('youtube') > -1){
				if(valueReturn.includes('youtu.be')){
					valueReturn = valueReturn.replace(/((https:\/\/youtu.be|http:\/\/youtu.be)[^\s]+)/g,'<br><br><iframe [class.thumbnail]="thumbnail" src="$1" width="560" height="315" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" webkitallowfullscreen mozallowfullscreen allowfullscreen></iframe><br><br>');
				}
			}
			if(args.indexOf('url') > -1){
				valueReturn = valueReturn.replace(/((?=http)(?=https)(?!https:\/\/youtu.be)(?!http:\/\/youtu.be)[^\s]+)/g, '<a href="$1" class="link" target="_blank">$1</a>');
			}

			if(args.indexOf('youtube') > -1){
				valueReturn = valueReturn.replace(/youtu.be/g, 'www.youtube.com/embed');
			}
		}
		valueReturn = valueReturn.replace(new RegExp("((<div><br><\/div>)*)$", 'g'),'');
		valueReturn = valueReturn.replace(/(?:\r\n|\r|\n)/g,'<br>');
		valueReturn = valueReturn.replace(new RegExp("((<br>)*)$", 'g'),'');
		return valueReturn;
	}

}
