import { ApplicationRef, Injectable, NgZone } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root',
})
export class KeyboardService {
    public heightKeyboard: number;
    private _visible: BehaviorSubject<number>;
    public visible: Observable<number>;

    private y: any;
    private h: any;
    private offsetY: any;

    constructor(private util: UtilService, private appRef: ApplicationRef, private ngZone: NgZone) {
        this.heightKeyboard = 0;
        this._visible = new BehaviorSubject(this.heightKeyboard);
        this.visible = this._visible.asObservable();
    }

    public getHeight = () => {
        return this.heightKeyboard;
    };

    public initKeyboardEvents = () => {
        if (this.util.inAppAndroid()) {
            //window.addEventListener('keyboardDidShow', this.listenerKeyboardShow);
            //window.addEventListener('keyboardWillHide', this.listenerKeyboardHide);
            window.addEventListener('ionKeyboardDidShow', this.listenerKeyboardShow);
            window.addEventListener('ionKeyboardDidHide', this.listenerKeyboardHide);
            window.addEventListener('touchstart', this.tapCoordinates);
        }
    };

    public focusInput = (id: string) => {
        setTimeout(() => {
            document.getElementById(id).scrollIntoView({ behavior: 'smooth', block: 'end' });
        });
    };

    private recalculateKeyboard = (event: any) => {
        this.heightKeyboard = Math.floor(event.keyboardHeight);
        let bodyMove = <HTMLElement>document.querySelector('ion-app'),
            bodyMoveStyle = bodyMove.style;
        /* if(this.util.inAppAndroid() && parseInt(this.util.infoDevice.osVersion) >= 8){
			this.heightKeyboard = this.heightKeyboard + 32;
		} */
        bodyMoveStyle.bottom = this.heightKeyboard + 'px';
        this._visible.next(this.heightKeyboard);
    };

    private listenerKeyboardShow = (event: any) => {
        console.log('keyboardDidShow -> listenerKeyboardShow', event);
        this.ngZone.run(() => {
            setTimeout(() => {
                this.recalculateKeyboard(event.detail);
                this.appRef.tick();
            });
        });
    };

    public hiddenAndroidEvent = () => {
        if (this.util.inAppAndroid()) {
            this.listenerKeyboardHide(true);
        }
    };

    private listenerKeyboardHide = (event: any) => {
        console.log('keyboardWillHide -> listenerKeyboardHide', event);
        this.ngZone.run(() => {
            this.heightKeyboard = 0;
            this._visible.next(this.heightKeyboard);
            let removeStyles = <HTMLElement>document.querySelector('ion-app');
            removeStyles.removeAttribute('style');
        });
    };

    private tapCoordinates = (e) => {
        this.y = e.touches[0].clientY;
        this.h = window.innerHeight;
        this.offsetY = Math.floor(this.h - this.y);
    };
}
