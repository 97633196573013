import { Component, ElementRef, ViewEncapsulation } from '@angular/core';

import { SubSink } from 'subsink';
import { ionicCustomAnimation } from './animations';
import { KeyboardService } from '@services/keyboard.service';
import { NetworkService } from '@services/network.service';
import { NotificationsService } from '@services/notifications.service';
import { PushService } from '@services/push.service';
import { RoutingStateService } from '@services/routing-state.service';
import { ThemeService } from '@services/theme.service';
import { UserService } from '@services/user.service';
import { UtilService } from '@services/util.service';
import { AnimationEvent } from '@angular/animations';
import { Router } from '@angular/router';

import { StatusBar, Style } from '@capacitor/status-bar';
import { SplashScreen } from '@capacitor/splash-screen';
import { TranslateService } from '@ngx-translate/core';
import { Title } from '@angular/platform-browser';
import { environment } from '../environments/environment';

@Component({
    selector: 'app-root',
    templateUrl: 'app.component.html',
    styleUrls: ['app.component.scss'],
    encapsulation: ViewEncapsulation.None,
    animations: [ionicCustomAnimation],
})
export class AppComponent {
    private subs = new SubSink();
    public animSplash: boolean;
    public initApp: boolean;

    public connToast: boolean;

    public offline: boolean;
    public offlineText: string;

    public iconApp: string;

    constructor(
        private titleService: Title,
        private router: Router,
        private network: NetworkService,
        private user: UserService,
        private util: UtilService,
        private translate: TranslateService,
        private notificationsService: NotificationsService,
        private pushService: PushService,
        private keyboardService: KeyboardService,
        private routingState: RoutingStateService,
        private elementRef: ElementRef,
        private themeService: ThemeService
    ) {
        this.offlineText = this.translate.instant('network.status.offline');
        this.offline = false;
        this.connToast = null;
        this.animSplash = false;
        this.initApp = this.util.inApp() ? false : true;
        this.initializeApp();
        this.titleService.setTitle(environment.appName);
        this.iconApp = `assets/icon/${environment.app}.png`;
    }

    public splashAnimationEvent = (event: AnimationEvent) => {
        console.log('splashAnimationEvent', event.phaseName, event.toState);
        if (event.phaseName == 'done' && parseInt(event.toState) == 1) {
            this.initApp = true;
        }
    };

    private async initializeApp() {
        this.routingState.initRouterState();
        this.network.initNetworkEvents();
        console.log('************ inAPP ', this.util.inApp());
        if (this.util.inApp()) {
            this.pushService.initConfig();
            this.keyboardService.initKeyboardEvents();
            await SplashScreen.hide();
            StatusBar.setStyle({ style: Style.Dark });
        } else {
            this.util.stopServiceWorkers().then((data) => {
                if (data.unregister === true) {
                    window.location.reload;
                }
            });
        }
        this.subs.add(
            this.user.authenticationState.subscribe(async (data) => {
                if (data) {
                    console.log('USER IS LOGGED');
                    if (this.util.inApp()) {
                        if (this.util.inAppIOS()) {
                            let _perms = await this.pushService.promptPermission();
                            console.log('Perms Push', _perms);
                            if (!_perms) {
                                console.log('NO TIENE PERMISOS PARA PUSH NOTIFICATIONS');
                            }
                        }
                        this.pushService.activatePushUser();
                    }
                    this.notificationsService.getPending();
                }
            }),
            this.user.logoutAction.subscribe((data) => {
                if (data.state === true) {
                    console.log('USER IS LOGOUT', data);
                    if (this.util.inApp()) {
                        this.pushService.clearPushUser();
                    }
                    this.notificationsService.resetAll();
                    if (data.redirect === true) {
                        setTimeout(() => {
                            this.router.navigate(['auth/login'], { replaceUrl: true });
                        });
                    }
                }
            }),
            this.themeService.theme.subscribe((theme) => {
                if (theme) {
                    this.themeService.changeTheme(this.elementRef);
                }
            }),
            this.network.$onNetworkChange().subscribe((status) => {
                console.log('$onNetworkChange', status);
                this.offline = status.connected ? false : true;
            }),
            this.routingState.routeState.subscribe((data) => {
                console.log('routingState routeState', this.routingState.getHistory());
                if (!this.animSplash && !this.initApp) {
                    if (this.routingState.getHistory().length > 0) {
                        setTimeout(() => {
                            this.animSplash = true;
                        }, 3000);
                    }
                }
            })
        );
    }
}
