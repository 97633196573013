import { Injectable } from '@angular/core';
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';
import { UserService } from '@services/user.service';


@Injectable({
    providedIn: 'root'
})
export class TokenGuard implements CanActivate {

    constructor(
        private user: UserService
    ) { }

    async canActivate(next: ActivatedRouteSnapshot, state: RouterStateSnapshot): Promise<boolean> {
		if (this.user.isAuthenticated()) {
            return true;
        } else {
        	let _auth = await this.user.checkToken();
			return true;
		}
    }
}
