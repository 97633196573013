import { NgModule } from '@angular/core';
import { SharedModule } from '@config/shared.module';
import { PipesModule } from '@pipes/pipes.module';
import { HeaderModalComponent } from './header-modal.component';

import { HeaderComponent } from './header.component';

@NgModule({
	declarations: [
		HeaderComponent,
		HeaderModalComponent,
	],
	imports: [
		SharedModule,
		PipesModule
	],
	exports: [
		HeaderComponent,
		HeaderModalComponent,
	]
})
export class HeaderModule { }
