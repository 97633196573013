import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

import { UtilService } from './util.service';
import { KeyboardService } from './keyboard.service';
import { ModalController } from '@ionic/angular';

@Injectable({
    providedIn: 'root',
})
export class RoutingStateService {
    private history: Array<string>;
    private _routeState: BehaviorSubject<string>;
    public routeState: Observable<string>;

    constructor(
        private modalController: ModalController,
        private keyboardService: KeyboardService,
        private util: UtilService,
        private router: Router
    ) {
        this.history = [];
        this._routeState = new BehaviorSubject('');
        this.routeState = this._routeState.asObservable();
    }

    public initRouterState = () => {
        this.router.events
            .pipe(filter((event) => event instanceof NavigationEnd))
            .subscribe(({ urlAfterRedirects }: NavigationEnd) => {
                this.history = [...this.history, urlAfterRedirects];
                console.log('HISTORY STATE ROUTER', this.history);
                if (this.util.inApp()) {
                    if (this.util.inAppAndroid()) {
                        this.keyboardService.hiddenAndroidEvent();
                    }
                }
                this._routeState.next(urlAfterRedirects);
                this.modalController.getTop().then((data) => {
                    if (typeof data != 'undefined') {
                        data.dismiss();
                    }
                });
            });
    };

    public getHistory = (): string[] => {
        return this.history;
    };

    public getPreviousUrl = (): string => {
        return this.history[this.history.length - 2] || '/';
    };

    public getUrl = (): string => {
        return this.history[this.history.length - 1] || '/';
    };
}
