import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { ActiveGuard } from './guards/active.guard';
import { AuthGuard } from './guards/auth.guard';
import { TokenGuard } from './guards/token.guard';
import { VersionGuard } from './guards/version.guard';
import { LinksResolveService } from './resolvers/links-resolve.service';

const routes: Routes = [
    {
        path: '',
        canActivate: [VersionGuard, AuthGuard],
        loadChildren: () => import('./pages/core/core.module').then((m) => m.CorePageModule),
    },
    {
        path: 'auth',
        canActivate: [VersionGuard, ActiveGuard],
        loadChildren: () => import('./pages/auth/auth.module').then((m) => m.AuthPageModule),
    },
    {
        path: 'links',
        resolve: {
            routeRedirect: LinksResolveService,
        },
        loadChildren: () => import('./pages/links/links.module').then((m) => m.LinksPageModule),
    },
    {
        path: 'signup',
        loadChildren: () => import('./pages/signup/signup.module').then((m) => m.SignupPageModule),
    },
    {
        path: 'issues',
        canActivate: [VersionGuard, TokenGuard],
        loadChildren: () => import('./pages/issues/issues.module').then((m) => m.IssuesPageModule),
    },
    {
        path: 'update',
        canActivate: [VersionGuard],
        loadChildren: () => import('./pages/update/update.module').then((m) => m.UpdatePageModule),
    },
    {
        path: 'not-found',
        canActivate: [VersionGuard],
        loadChildren: () => import('./pages/not-found/not-found.module').then((m) => m.NotFoundPageModule),
    },
    {
        path: 'register/:id',
        redirectTo: 'auth/register/:id',
        pathMatch: 'full',
    },
    {
        path: '**',
        redirectTo: '/not-found',
        pathMatch: 'full',
    },
];

@NgModule({
    imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
    exports: [RouterModule],
})
export class AppRoutingModule {}
