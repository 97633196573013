import { Injectable } from '@angular/core';
import { Observable, BehaviorSubject, throwError } from 'rxjs';
import { map, catchError, take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { httpData } from '@models/data.model';
import { UtilService } from './util.service';
import { OSNotificationPayload, OSNotification } from '@ionic-native/onesignal/ngx';
import { NotificationModel } from '@models/notifications.model';
import { environment } from '../../environments/environment';

import { Storage } from '@capacitor/storage';

@Injectable({
  providedIn: 'root'
})
export class NotificationsService {

	public notificationsPush: Observable<OSNotificationPayload[]>;
	private _notificationsPush: BehaviorSubject<OSNotificationPayload[]>;

	public  notifications: Observable<NotificationModel[]>;
	private _notifications: BehaviorSubject<NotificationModel[]>;

	public pending: Observable<number>;
	private _pending: BehaviorSubject<number>;

	private _newNotification: BehaviorSubject<number>;
	public newNotification: Observable<number>;

	private dataStore: {
		notificationsPush: OSNotificationPayload[],
		notifications:NotificationModel[],
		pending:number
    };

  	constructor(
		private api:ApiService,
		private util: UtilService,
	) {
		//Storage.migrate();
		this.dataStore = {
			notificationsPush: [],
			notifications: [],
			pending:0
		};
		this._notificationsPush = new BehaviorSubject([]);
		this.notificationsPush = this._notificationsPush.asObservable();
		this._notifications = new BehaviorSubject([]);
		this.notifications = this._notifications.asObservable();
		this._pending = new BehaviorSubject(0);
		this.pending = this._pending.asObservable();
		this._newNotification = new BehaviorSubject(0);
		this.newNotification = this._newNotification.asObservable();
	}

	async setNewNotificationPush(notification:OSNotification){
		await this.loadNotificationsPush();
		const payload = notification.payload;
		const existPush = this.dataStore.notificationsPush.find(msg => msg.notificationID === payload.notificationID);
		if(!existPush){
			this.dataStore.notificationsPush.unshift(payload);
			this._notificationsPush.next(Object.assign({}, this.dataStore).notificationsPush);
			this.storeNotificationsPush();
		}
		this._newNotification.next(this.dataStore.notificationsPush.length);
	}

	async loadNotificationsPush(){
		this.dataStore.notificationsPush = [];
		try {
			let _storeString = await Storage.get({key:`${environment.STORAGE_APP}-pushNotifications`});
			if(_storeString.value){
				let _storeData = JSON.parse(_storeString.value);
				this.dataStore.notificationsPush = _storeData;
			}
		} catch (error) {
			console.log('Error get storage notifications');
		}
		this._notificationsPush.next(Object.assign({}, this.dataStore).notificationsPush);
		this._newNotification.next(this.dataStore.notificationsPush.length);
        return this.dataStore.notificationsPush;
	}

	private storeNotificationsPush = () => {
		Storage.set({key:`${environment.STORAGE_APP}-pushNotifications`, value:JSON.stringify(this.dataStore.notificationsPush)});
	}

	public deleteNotificationPush = (id:string) => {
		let _index = this.dataStore.notificationsPush.findIndex(item => item.additionalData.id == id);
		if(_index > -1){
			this.dataStore.notificationsPush.splice(_index,1);
			this._notificationsPush.next(Object.assign({}, this.dataStore).notificationsPush);
			this.storeNotificationsPush();
			this._newNotification.next(this.dataStore.notificationsPush.length);
		}
	}

	public clearStoragePush = () => {
		this.dataStore.notificationsPush = [];
		this._notificationsPush.next(Object.assign({}, this.dataStore).notificationsPush);
		this.storeNotificationsPush();
		this._newNotification.next(this.dataStore.notificationsPush.length);
	}

	public resetAll = () => {
		this.resetNotifications();
		this.resetPending();
	}

	public resetNotifications = () => {
		this.dataStore.notifications = [];
        this._notifications.next(Object.assign({}, this.dataStore).notifications);
	}

	public resetPending = () => {
		this.dataStore.pending = 0;
		this._pending.next(Object.assign({}, this.dataStore).pending);
	}

	public getNotifications = (dataSend:any = {}): Observable<any> => {
		return this.api.get('notifications', dataSend).pipe(
            map((data: httpData) => {
				this.dataStore.notifications = data.data.notifications;
                this._notifications.next(Object.assign({}, this.dataStore).notifications);
                return data.data;
            }),
            catchError( error => {return this.util.apiErrorReturn(error);})
		)
	}

	public getNotification = (dataSend:any): Observable<any> => {
		return this.api.get(`notifications/${dataSend.hashid}`, dataSend).pipe(
            map((data: httpData) => {
                return data.data;
            }),
            catchError( error => {return this.util.apiErrorReturn(error);})
		)
	}

	public getPending = (): void => {
		this.api.get('notifications/pending', {}).pipe(
            map( (data:httpData) => {
                this.dataStore.pending = data.data.pending;
				this._pending.next(Object.assign({}, this.dataStore).pending);
                return true;
            }),
            catchError( error => {return this.util.apiErrorReturn(error);})
        ).pipe(take(1)).subscribe();
	}

	public viewNotifications = (dataSend:any = {}): Observable<any> => {
		return this.api.post('notifications/viewed', dataSend).pipe(
            map( (data:httpData) => {
                return true;
            }),
            catchError( error => {return this.util.apiErrorReturn(error);})
        );
	}

}
