import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({
  	name: 'formatDateMoment'
})
export class FormatDateMomentPipe implements PipeTransform {
	transform(value:any,format:string = 'DD/MM/YYYY'):string {
		if(value){
			return moment(value).format(format);
		}
		return '';
	}

}
