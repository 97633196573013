export interface MenuInterface {
    icon:string;
    iconType:number;
    name:string;
	redirectTo:string;
	settingConfig?:string;
	hidden?:boolean;
	active?:boolean;
}

export interface httpData {
    error: string;
    errorNo?: string;
    data?: any;
}

export class clockFormatModel{
	constructor(
		public days:string = "",
		public hours:string = "",
		public minutes:string = "",
		public seconds:string = "",
		public thousandths:string = ""
	){}
}

export interface IntervalInterface{
	element:any;
	actual:number;
	speed:number;
	total:number;
	percent:number;
}

export class alertCustomOptions{
	constructor(
		public texts:string[] = [],
		public translate?:boolean,
		public params?:any,
		public config?:any,
		public buttons?:string[],
		public onKeyPressEnter?:any
	){}
}

export interface IonAlertCustomOptions {
	header?:string;
	message?:string;
	buttons?:{text:string,role:string}[];
	paramsTexts?:any;
	config?:any;
}

export class validateEntryData{
	constructor(
		public field:any,
		public type:string,
		public filter?:any
	){}
}

export class validateReturnData{
	constructor(
		public error:boolean,
		public errorNo?:number
	){}
}
