import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { ConfigModule } from '@config/config.module';

import { CallNumber } from '@ionic-native/call-number/ngx';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { OneSignal } from '@ionic-native/onesignal/ngx';
import { Badge } from '@ionic-native/badge/ngx';
import { UrlViewerModule } from './components/url-viewer/url-viewer.component.module';
import { PdfViewerModule } from './components/pdf-viewer/pdf-viewer.component.module';

@NgModule({
	declarations: [AppComponent],
	entryComponents: [],
	imports: [
		BrowserModule,
		BrowserAnimationsModule,
		IonicModule.forRoot(),
		AppRoutingModule,
		ConfigModule,
		UrlViewerModule,
		PdfViewerModule,
	],
	providers: [
		CallNumber,
		OneSignal,
		Badge,
		PreviewAnyFile,
		{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }
	],
	bootstrap: [AppComponent]
})
export class AppModule {}
