import { Injectable } from '@angular/core';
import { clockFormatModel, alertCustomOptions, IonAlertCustomOptions } from '../models/data.model';
import { TranslateService } from '@ngx-translate/core';
import { AlertController, ToastController, LoadingController, Platform } from '@ionic/angular';
import { ApiService } from './api.service';
import { throwError } from 'rxjs';

import { CallNumber } from '@ionic-native/call-number/ngx';

import * as _ from 'lodash';

import { FormControl, FormGroup } from '@angular/forms';
import { ValidatorMessages } from './validators.service';

import { Capacitor } from '@capacitor/core';
import { Device, DeviceInfo } from '@capacitor/device';

@Injectable({
    providedIn: 'root'
})
export class UtilService {

    loading:any;
	intervalLoading:any;

	public infoDevice:DeviceInfo;

	private lastApiError:number;

    constructor(
		private callNumber:CallNumber,
		private api:ApiService,
        private translate:TranslateService,
        private alertController:AlertController,
        private toastController:ToastController,
        private platform:Platform,
        private loadingController:LoadingController,
	) {
		this.infoDevice = null;
		this.lastApiError = -1;
	}

	public stopServiceWorkers = ():Promise<any> => {
		return new Promise(resolve => {
			try {
				let _unregister = {unregister:false};
				if(window.navigator && navigator.serviceWorker) {
					navigator.serviceWorker.getRegistrations().then( function(registrations) {
						console.log('***************** serviceWorker registrations -> ',registrations);
						if(registrations) {
							for(let registration of registrations) {
								registration.unregister();
								_unregister = {unregister:true};
								console.log('UNREGISTER serviceWorker', registration);
							}
						}
					});
				}
				resolve(_unregister);
			} catch (error) {
				resolve({unregister:false});
				console.log('error serviceWorker', error);
			}
		})
	}

	public isFormInvalid = (formGroup: FormGroup, field:string):boolean => {
        return (!formGroup.get(field).valid && (formGroup.get(field).dirty || formGroup.get(field).touched));
	}
	public getErrorFrom = (formGroup: FormGroup, field:string, formMessages:string[]):string => {
        let _errorMsg = '';
        formMessages.forEach(control => {
            if(formGroup.get(field).hasError(control) && _errorMsg == ''){
                _errorMsg = ValidatorMessages.get(control);
            }
        });
        return _errorMsg;
	}
	public validateAllFormFields = (formGroup: FormGroup) => {
        Object.keys(formGroup.controls).forEach(field => {
            const control = formGroup.get(field);
            if (control instanceof FormControl) {
                control.markAsTouched({ onlySelf: true });
            } else if (control instanceof FormGroup) {
                this.validateAllFormFields(control);
            }
        });
	}
	public trimAllFormFields = (formGroup: FormGroup) => {
        Object.keys(formGroup.controls).forEach(field => {
			const control = formGroup.get(field).value;
			try {
				if (typeof control === 'string' || control instanceof String) formGroup.get(field).setValue(control.trim());
			} catch (error) {
				console.log(error);
			}
        });
	}

	public async loadDeviceInfo(){
		this.infoDevice = await Device.getInfo();
		return true;
	}
	public getDeviceInfo(){
		return this.infoDevice;
	}

	public callPhone = (phone:string) => {
		phone = _.replace(phone,/[^0-9.]/g, '');
		//if(_.startsWith(phone, '34')){phone = '+'+phone;}
		if(this.inApp()){
			return this.callNumber.callNumber(phone, true);
		}else{
			return new Promise((resolve) => {
				try {
					window.open(`tel://${phone}`,'_system');
					resolve(true);
				} catch (error) {
					throw error;
				}
			});
		}
	}

	public apiErrorReturn = (error:any) => {
		let _errorNo = this.api.handleError(error);
        switch (_errorNo) {
            case 401:
            case 501:
            case 502:
                if (_errorNo === this.lastApiError) {
                    _errorNo = null;
                }
                break;
        }
        if (_errorNo) {
            this.lastApiError = _errorNo;
            let _errorMessage = _errorNo > 400 ? `common.error.${_errorNo}` : error.error.message;
            this.alert({ texts: [_errorMessage, 'common.close'], translate: _errorNo > 400 });
        }
        return throwError(error);
	}

	private buttonText = (value:string) => {
		return String(value).charAt(0).toUpperCase() + String(value).slice(1).toLowerCase();
	}

	public inApp = () => {
		return (this.platform.is('hybrid') && Capacitor.isNativePlatform());
	}

	public inAppAndroid = () => {
		return (this.platform.is('hybrid') && Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'android');
	}

	public inAppIOS = () => {
		return (this.platform.is('hybrid') && Capacitor.isNativePlatform() && Capacitor.getPlatform() == 'ios');
	}

	public inMSWindows = () => {
		return (!Capacitor.isNativePlatform() && this.infoDevice.operatingSystem == 'windows' && this.infoDevice.platform == 'web');
	}

	public inSafariMobile = () => {
		return (!Capacitor.isNativePlatform() && this.infoDevice.operatingSystem == 'ios' && this.infoDevice.platform == 'web' && this.platform.testUserAgent('Safari'));
	}

    clockFormat = (timeToFormat:number,format?:string):clockFormatModel => {
        let _format = format || 'days';
        let times = timeToFormat;
        let d = Math.floor(times/24/60/60/1000);
        let h_res = (_format == 'hours') ? times : times - (d*24*60*60*1000);
        let h = Math.floor(h_res/60/60/1000);
        let m_res = (_format == 'minutes') ? times : h_res - (h*60*60*1000);
        let m = Math.floor(m_res/60/1000);
        let s_res = (_format == 'seconds') ? times : m_res - (m*60*1000);
        let s = Math.floor(s_res/1000);
        let ms_res = s_res - (s*1000);
        let ms = ms_res;
        let text_d = (_format == 'hours') ? '' : (d<10) ? '0'+d : d.toString();
        let text_h = (_format == 'minutes') ? '' : (h<10) ? '0'+h : h.toString();
        let text_m = (_format == 'seconds') ? '' : (m<10) ? '0'+m : m.toString();
        let text_s = (s<10) ? '0'+s : s.toString();
        //let text_ms = (ms<10) ? '000'+ms : (ms<100) ? '00'+ms : (ms<1000) ? '0'+ms : ms.toString();
        let text_ms = (ms<10) ? '00'+ms : (ms<100) ? '0'+ms : ms.toString();
        let return_text = new clockFormatModel();
        return_text.days = text_d;
        return_text.hours = text_h;
        return_text.minutes = text_m;
        return_text.seconds = text_s;
        return_text.thousandths = text_ms;
        return (return_text);
    };

    async presentAlert(configAlert:any) {
        const alert = await this.alertController.create(configAlert);
        await alert.present();
    }
    alert = (options:alertCustomOptions):void => {
		let _params =  options.params || {};
        this.translate.get(options.texts,_params).subscribe(valueText => {
            let _base = {
				cssClass: 'cm-ion-alert',
                message: valueText[options.texts[0]],
                buttons: [
                    {
						text: this.buttonText(valueText[options.texts[1]])
					}
                ],
                backdropDismiss: false
            };
            let _newConfig =  options.config || {};
            let _config = {..._base,..._newConfig};
            this.presentAlert(_config);
        });
	}

	alertConfirm = (options:alertCustomOptions):Promise<boolean> => {
		return new Promise((resolve) => {
			let _params =  options.params || {};
			this.translate.get(options.texts,_params).subscribe(valueText => {
				console.log(valueText);
				let _base = {
					header: valueText[options.texts[2]],
					message: valueText[options.texts[3]],
					cssClass: 'cm-ion-alert',
					buttons: [
						{
							text: this.buttonText(valueText[options.texts[0]]),
							role: 'cancel',
							handler: () => {
								resolve(false);
							}
						}, {
							text:this.buttonText(valueText[options.texts[1]]),
							handler: () => {
								resolve(true);
							}
						}
					],
					backdropDismiss: false
				};
				let _newConfig =  options.config || {};
            	let _config = {..._base,..._newConfig};
				this.alertController.create(_config).then(modal => { modal.present();});
			});
		});
	}

	alertAction = (options:alertCustomOptions):Promise<boolean> => {
		return new Promise((resolve) => {
			let _params =  options.params || {};
			this.translate.get(options.texts,_params).subscribe(valueText => {
				this.alertController.create({
					header: valueText[options.texts[1]],
					message: valueText[options.texts[2]],
					cssClass: 'cm-ion-alert',
					buttons: [
						{
							text:this.buttonText(valueText[options.texts[0]]),
							handler: () => {
								resolve(true);
							}
						}
					],
					backdropDismiss: false
				}).then(modal => { modal.present();});
			});
		});
	}

	alertButtons = (options:IonAlertCustomOptions):Promise<any> => {
		return new Promise((resolve) => {
			let _paramsText = (options.paramsTexts) ? options.paramsTexts : {};
			let _base = {
				header: (options.header) ? this.translate.instant(options.header,_paramsText) : '',
				message: (options.message) ? this.translate.instant(options.message,_paramsText) : '',
				cssClass: 'cm-ion-alert-buttons',
				buttons: [],
				backdropDismiss: false
			};
			let _buttons =  options.buttons || [];
			if(_buttons.length > 0){
				_buttons.forEach((item, index) => {
					_base.buttons.push({
						text: this.buttonText(this.translate.instant(item.text)),
						role: (item.role) ? item.role : null,
						handler: () => {
							resolve({button:index});
						}
					})
				})
				let _newConfig =  options.config || {};
				let _config = {..._base,..._newConfig};
				this.alertController.create(_config).then(modal => { modal.present();});
			}else{
				resolve(null);
			}
		});
	}

    alertInput = (options:alertCustomOptions):Promise<any> => {
		return new Promise((resolve) => {
			let _params =  options.params || {};
			this.translate.get(options.texts,_params).subscribe(valueText => {
				let alertEl:HTMLIonAlertElement = null;
				this.alertController.create({
					header: valueText[options.texts[2]],
					message: valueText[options.texts[3]],
					cssClass: 'cm-ion-alert',
					buttons: [
						{
							text: this.buttonText(valueText[options.texts[0]]),
							role: 'cancel',
							handler: () => {
								alertEl.dismiss(false);
							}
						}, {
							text:this.buttonText(valueText[options.texts[1]]),
							role: 'confirm',
							handler: (data) => {
								alertEl.dismiss(data);
							}
						}
					],
                    inputs: [
                        {
                            name: options.texts[4],
                            type: options.config?.inputType ?? 'text',
                            placeholder: valueText[options.texts[5]],
                            cssClass: 'cm-alert-input',
							attributes: {
								onKeyPress: ($event:any) => {
									if(options.onKeyPressEnter && $event.keyCode == 13){
										const value = {values:{}};
										value.values[options.texts[4]] = $event.target.value;
										alertEl.dismiss(value);
									}
								}
							}
                        }
                    ],
					backdropDismiss: false
				}).then(modal => {
					alertEl = modal;
                    alertEl.present().then(() => {
                        const firstInput: any = document.querySelector('ion-alert input');
                        firstInput.focus();
                        return;
                    });
					alertEl.onDidDismiss().then(result => {
						if(result.role == 'cancel'){
							resolve(false);
						}else{
							resolve(result.data.values);
						}
					})
                });
			});
		});
	}

    async presentToast(configToast:any) {
        const toast = await this.toastController.create(configToast);
        await toast.present();

    }
    toast = (options:alertCustomOptions):void => {
		let _params =  options.params || {};
        this.translate.get(options.texts,_params).subscribe(valueText => {
            let _base = {
				message: valueText[options.texts[0]],
				duration: 3000,
				cssClass:'cm-ion-toast',
				buttons:[
					{
						text:this.buttonText(valueText[options.texts[1]])
					}
				]
            };
            let _newConfig =  options.config || {};
            let _config = {..._base,..._newConfig};
            this.presentToast(_config);
        });
	}
	public toastDismiss = () => {
		this.toastController.getTop().then(data => {
			if(typeof(data) != 'undefined'){
				data.dismiss();
			}
		});
	}



    clearLoading = ():void => {
        if(this.intervalLoading) clearInterval(this.intervalLoading);
        if(this.loading) this.loading.dismiss();
    }
    stopLoading = (repeat?:number):void => {
        let _times = repeat || 50;
        let _count = 0;
        if(this.intervalLoading) clearInterval(this.intervalLoading);
        this.intervalLoading = setInterval(()=>{
            console.log('Interval Loading Dismiss');
            _count++;
            if(this.loading){
                this.loading.dismiss();
                clearInterval(this.intervalLoading);
            }else if(_count >= _times){
                clearInterval(this.intervalLoading);
            }
        },100);
    }
    startLoading = (options:alertCustomOptions):void => {
		let _params =  options.params || {};
        this.translate.get(options.texts, _params).subscribe(valueText => {
            let _base = {
				cssClass:'cm-ion-loading',
                backdropDismiss: false,
                message: valueText[options.texts[0]]
            };
            let _newConfig =  options.config || {};
			let _config = {..._base,..._newConfig};
            this.presentLoading(_config);
        });
    }
    async presentLoading(configLoading:any) {
        this.loading = await this.loadingController.create(configLoading);
        await this.loading.present();
    }

	public uniqId = () => {
		let ts=String(new Date().getTime()), i = 0, out = '';
		for(i=0;i<ts.length;i+=2) {
		   out+=Number(ts.substr(i, 2)).toString(36);
		}
		return 'id'+out;
	}

	public dataURLtoBlob = (dataURL:string) => {
		var binary = atob(dataURL.split(',')[1]);
		var array = [];
		for(var i = 0; i < binary.length; i++) {array.push(binary.charCodeAt(i));}
		return new Blob([new Uint8Array(array)], {type: 'image/jpg'});
	}

	public dataURLtoFile = (dataUrl:string, filename:string) => {
		var arr = dataUrl.split(','), mime = arr[0].match(/:(.*?);/)[1],
			bstr = atob(arr[1]), n = bstr.length, u8arr = new Uint8Array(n);
		while(n--){
			u8arr[n] = bstr.charCodeAt(n);
		}
		return new File([u8arr], filename, {type:mime});
	}

	public b64toBlob(b64Data:string) {
		var arr = b64Data.split(',');
		var mime = arr[0].match(/:(.*?);/)[1];
		var sliceSize = 512;
		var byteCharacters = atob(arr[1]);
		var byteArrays = [];
		for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
			var slice = byteCharacters.slice(offset, offset + sliceSize);
			var byteNumbers = new Array(slice.length);
			for (var i = 0; i < slice.length; i++) {
				byteNumbers[i] = slice.charCodeAt(i);
			}
			var byteArray = new Uint8Array(byteNumbers);
			byteArrays.push(byteArray);
		}
		return new Blob(byteArrays, {type: mime});
	}

	public async createFile(dataURL:string,filename:string){
		let response = await fetch(dataURL);
		let data = await response.blob();
		let ext = dataURL.split('.').pop();
		let  metadata = {
			type:`image/${ext}`
		};
		let file = new File([data], `${filename}.${ext}`, metadata);
		return file;
	}

	public fileToDataUrl = (file:File) => { 
		return new Promise((resolve) => {
			let reader = new FileReader();
			reader.onloadend = () => {
				resolve(reader.result);
			}
			reader.onerror = () => {
				resolve(null);
			}
			reader.readAsDataURL(file);
		});
	}

	public mime2ext = (mimeFile:string) => {
        let mimeMap = [
            {mime:'video/3gpp2',ext:'3g2'},
            {mime:'video/3gp',ext:'3gp'},
            {mime:'video/3gpp',ext:'3gp'},
            {mime:'application/x-compressed',ext:'7zip'},
            {mime:'audio/x-acc',ext:'aac'},
            {mime:'audio/ac3',ext:'ac3'},
            {mime:'application/postscript',ext:'ai'},
            {mime:'audio/x-aiff',ext:'aif'},
            {mime:'audio/aiff',ext:'aif'},
            {mime:'audio/x-au',ext:'au'},
            {mime:'video/x-msvideo',ext:'avi'},
            {mime:'video/msvideo',ext:'avi'},
            {mime:'video/avi',ext:'avi'},
            {mime:'application/x-troff-msvideo',ext:'avi'},
            {mime:'application/macbinary',ext:'bin'},
            {mime:'application/mac-binary',ext:'bin'},
            {mime:'application/x-binary',ext:'bin'},
            {mime:'application/x-macbinary',ext:'bin'},
            {mime:'image/bmp',ext:'bmp'},
            {mime:'image/x-bmp',ext:'bmp'},
            {mime:'image/x-bitmap',ext:'bmp'},
            {mime:'image/x-xbitmap',ext:'bmp'},
            {mime:'image/x-win-bitmap',ext:'bmp'},
            {mime:'image/x-windows-bmp',ext:'bmp'},
            {mime:'image/ms-bmp',ext:'bmp'},
            {mime:'image/x-ms-bmp',ext:'bmp'},
            {mime:'application/bmp',ext:'bmp'},
            {mime:'application/x-bmp',ext:'bmp'},
            {mime:'application/x-win-bitmap',ext:'bmp'},
            {mime:'application/cdr',ext:'cdr'},
            {mime:'application/coreldraw',ext:'cdr'},
            {mime:'application/x-cdr',ext:'cdr'},
            {mime:'application/x-coreldraw',ext:'cdr'},
            {mime:'image/cdr',ext:'cdr'},
            {mime:'image/x-cdr',ext:'cdr'},
            {mime:'zz-application/zz-winassoc-cdr',ext:'cdr'},
            {mime:'application/mac-compactpro',ext:'cpt'},
            {mime:'application/pkix-crl',ext:'crl'},
            {mime:'application/pkcs-crl',ext:'crl'},
            {mime:'application/x-x509-ca-cert',ext:'crt'},
            {mime:'application/pkix-cert',ext:'crt'},
            {mime:'text/css',ext:'css'},
            {mime:'text/x-comma-separated-values',ext:'csv'},
            {mime:'text/comma-separated-values',ext:'csv'},
            {mime:'application/vnd.msexcel',ext:'csv'},
            {mime:'application/x-director',ext:'dcr'},
            {mime:'application/vnd.openxmlformats-officedocument.wordprocessingml.document',ext:'docx'},
            {mime:'application/x-dvi',ext:'dvi'},
            {mime:'message/rfc822',ext:'eml'},
            {mime:'application/x-msdownload',ext:'exe'},
            {mime:'video/x-f4v',ext:'f4v'},
            {mime:'audio/x-flac',ext:'flac'},
            {mime:'video/x-flv',ext:'flv'},
            {mime:'image/gif',ext:'gif'},
            {mime:'application/gpg-keys',ext:'gpg'},
            {mime:'application/x-gtar',ext:'gtar'},
            {mime:'application/x-gzip',ext:'gzip'},
            {mime:'application/mac-binhex40',ext:'hqx'},
            {mime:'application/mac-binhex',ext:'hqx'},
            {mime:'application/x-binhex40',ext:'hqx'},
            {mime:'application/x-mac-binhex40',ext:'hqx'},
            {mime:'text/html',ext:'html'},
            {mime:'image/x-icon',ext:'ico'},
            {mime:'image/x-ico',ext:'ico'},
            {mime:'image/vnd.microsoft.icon',ext:'ico'},
            {mime:'text/calendar',ext:'ics'},
            {mime:'application/java-archive',ext:'jar'},
            {mime:'application/x-java-application',ext:'jar'},
            {mime:'application/x-jar',ext:'jar'},
            {mime:'image/jp2',ext:'jp2'},
            {mime:'video/mj2',ext:'jp2'},
            {mime:'image/jpx',ext:'jp2'},
            {mime:'image/jpm',ext:'jp2'},
            {mime:'image/jpg',ext:'jpg'},
            {mime:'image/jpeg',ext:'jpeg'},
            {mime:'image/pjpeg',ext:'jpeg'},
            {mime:'application/x-javascript',ext:'js'},
            {mime:'application/json',ext:'json'},
            {mime:'text/json',ext:'json'},
            {mime:'application/vnd.google-earth.kml+xml',ext:'kml'},
            {mime:'application/vnd.google-earth.kmz',ext:'kmz'},
            {mime:'text/x-log',ext:'log'},
            {mime:'audio/x-m4a',ext:'m4a'},
            {mime:'application/vnd.mpegurl',ext:'m4u'},
            {mime:'audio/midi',ext:'mid'},
            {mime:'application/vnd.mif',ext:'mif'},
            {mime:'video/quicktime',ext:'mov'},
            {mime:'video/x-sgi-movie',ext:'movie'},
            {mime:'audio/mpeg',ext:'mp3'},
            {mime:'audio/mpg',ext:'mp3'},
            {mime:'audio/mpeg3',ext:'mp3'},
            {mime:'audio/mp3',ext:'mp3'},
            {mime:'video/mp4',ext:'mp4'},
            {mime:'video/mpeg',ext:'mpeg'},
            {mime:'application/oda',ext:'oda'},
            {mime:'audio/ogg',ext:'ogg'},
            {mime:'video/ogg',ext:'ogg'},
            {mime:'application/ogg',ext:'ogg'},
            {mime:'application/x-pkcs10',ext:'p10'},
            {mime:'application/pkcs10',ext:'p10'},
            {mime:'application/x-pkcs12',ext:'p12'},
            {mime:'application/x-pkcs7-signature',ext:'p7a'},
            {mime:'application/pkcs7-mime',ext:'p7c'},
            {mime:'application/x-pkcs7-mime',ext:'p7c'},
            {mime:'application/x-pkcs7-certreqresp',ext:'p7r'},
            {mime:'application/pkcs7-signature',ext:'p7s'},
            {mime:'application/pdf',ext:'pdf'},
            {mime:'application/octet-stream',ext:'pdf'},
            {mime:'application/x-x509-user-cert',ext:'pem'},
            {mime:'application/x-pem-file',ext:'pem'},
            {mime:'application/pgp',ext:'pgp'},
            {mime:'application/x-httpd-php',ext:'php'},
            {mime:'application/php',ext:'php'},
            {mime:'application/x-php',ext:'php'},
            {mime:'text/php',ext:'php'},
            {mime:'text/x-php',ext:'php'},
            {mime:'application/x-httpd-php-source',ext:'php'},
            {mime:'image/png',ext:'png'},
            {mime:'image/x-png',ext:'png'},
            {mime:'application/powerpoint',ext:'ppt'},
            {mime:'application/vnd.ms-powerpoint',ext:'ppt'},
            {mime:'application/vnd.ms-office',ext:'ppt'},
            {mime:'application/msword',ext:'doc'},
            {mime:'application/vnd.openxmlformats-officedocument.presentationml.presentation',ext:'pptx'},
            {mime:'application/x-photoshop',ext:'psd'},
            {mime:'image/vnd.adobe.photoshop',ext:'psd'},
            {mime:'audio/x-realaudio',ext:'ra'},
            {mime:'audio/x-pn-realaudio',ext:'ram'},
            {mime:'application/x-rar',ext:'rar'},
            {mime:'application/rar',ext:'rar'},
            {mime:'application/x-rar-compressed',ext:'rar'},
            {mime:'audio/x-pn-realaudio-plugin',ext:'rpm'},
            {mime:'application/x-pkcs7',ext:'rsa'},
            {mime:'text/rtf',ext:'rtf'},
            {mime:'text/richtext',ext:'rtx'},
            {mime:'video/vnd.rn-realvideo',ext:'rv'},
            {mime:'application/x-stuffit',ext:'sit'},
            {mime:'application/smil',ext:'smil'},
            {mime:'text/srt',ext:'srt'},
            {mime:'image/svg',ext:'svg'},
            {mime:'image/svg+xml',ext:'svg'},
            {mime:'application/x-shockwave-flash',ext:'swf'},
            {mime:'application/x-tar',ext:'tar'},
            {mime:'application/x-gzip-compressed',ext:'tgz'},
            {mime:'image/tiff',ext:'tiff'},
            {mime:'text/plain',ext:'txt'},
            {mime:'text/x-vcard',ext:'vcf'},
            {mime:'application/videolan',ext:'vlc'},
            {mime:'text/vtt',ext:'vtt'},
            {mime:'audio/x-wav',ext:'wav'},
            {mime:'audio/wave',ext:'wav'},
            {mime:'audio/wav',ext:'wav'},
            {mime:'application/wbxml',ext:'wbxml'},
            {mime:'video/webm',ext:'webm'},
            {mime:'audio/x-ms-wma',ext:'wma'},
            {mime:'application/wmlc',ext:'wmlc'},
            {mime:'video/x-ms-wmv',ext:'wmv'},
            {mime:'video/x-ms-asf',ext:'wmv'},
            {mime:'application/xhtml+xml',ext:'xhtml'},
            {mime:'application/excel',ext:'xl'},
            {mime:'application/msexcel',ext:'xls'},
            {mime:'application/x-msexcel',ext:'xls'},
            {mime:'application/x-ms-excel',ext:'xls'},
            {mime:'application/x-excel',ext:'xls'},
            {mime:'application/x-dos_ms_excel',ext:'xls'},
            {mime:'application/xls',ext:'xls'},
            {mime:'application/x-xls',ext:'xls'},
            {mime:'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',ext:'xlsx'},
            {mime:'application/vnd.ms-excel',ext:'xlsx'},
            {mime:'application/xml',ext:'xml'},
            {mime:'text/xml',ext:'xml'},
            {mime:'text/xsl',ext:'xsl'},
            {mime:'application/xspf+xml',ext:'xspf'},
            {mime:'application/x-compress',ext:'z'},
            {mime:'application/x-zip',ext:'zip'},
            {mime:'application/zip',ext:'zip'},
            {mime:'application/x-zip-compressed',ext:'zip'},
            {mime:'application/s-compressed',ext:'zip'},
            {mime:'multipart/x-zip',ext:'zip'},
            {mime:'text/x-scriptzsh',ext:'zsh'}
		];
		let element = mimeMap.find(item => item.mime == mimeFile);
		if(element) return element.ext;
		return false;
	}

	public ext2Type = (ext:string) => {
		let $type = -1;
		switch (ext) {
			case 'pdf':
				$type = 0;
				break;
			case 'docx':
			case 'doc':
			case 'dot':
			case 'txt':
			case 'docm':
			case 'dotm':
				$type = 1;
				break;
			case 'xlsx':
			case 'xlsm':
			case 'xlsb':
			case 'xltx':
			case 'xltm':
			case 'xls':
			case 'xlt':
			case 'numbers':
				$type = 2;
				break;
			case 'pptx':
			case 'pptm':
			case 'ppt':
			case 'xps':
			case 'potx':
			case 'potm':
			case 'pot':
			case 'ppsx':
			case 'ppsm':
			case 'pps':
				$type = 3;
				break;
			case 'bmp':
			case 'gif':
			case 'jpg':
			case 'jpeg':
			case 'tif':
			case 'svg':
			case 'tiff':
			case 'png':
				$type = 4;
				break;
			case 'wav':
			case 'mp3':
			case 'ogg':
			case 'midi':
				$type = 5;
				break;
			case 'avi':
			case 'mpeg':
			case 'mov':
			case 'wmv':
			case 'rm':
			case 'flv':
				$type = 6;
				break;
			default:
				$type = 10;
				break;
		}
		return $type;
	}

}