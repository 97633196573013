import { Component, HostListener, OnInit, ViewEncapsulation } from '@angular/core';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
	selector: 'app-url-viewer-modal',
	templateUrl: './url-viewer-modal.component.html',
	styleUrls: ['./url-viewer-modal.component.scss'],
	encapsulation: ViewEncapsulation.None
})
export class UrlViewerModalComponent implements OnInit {

	public titleTx:string = '';
	public urlIframe:string = '';

	constructor(
		private modalController: ModalController,
		private navParams: NavParams,
	) {
		this.titleTx = (this.navParams.get('titleTx')) ? this.navParams.get('titleTx') : '';
		this.urlIframe = (this.navParams.get('urlIframe')) ? this.navParams.get('urlIframe') : '';
	}

	public closeModal() {
        this.modalController.dismiss();
	}

	ngOnInit() {}

}
