export const environment = {
    production: false,
    app: 'rhparking',
    appName: 'RHParking',
    appBundle: 'com.rhparking.nottinghamcity',
    clientId: '13c2021700a1c2bdbe4705dd06',
    serverName: 'localhost',
    PATH_WEB: 'https://path.inserious.com',
    PATH_API: 'https://path.inserious.com/api/',
    VERSION_API: 'v1/',
    PATH_NODE: '',
    LINK_APP_IOS: 'https://apps.apple.com/us/app/rhparking/id1484671395?l=es&ls=1',
    LINK_APP_ANDROID: 'https://play.google.com/store/apps/details?id=com.rhparking.nottinghamcity',
    ONESIGNAL_ID: '7f348ea9-1ab7-4093-ab7c-66314f69a504',
    ONESIGNAL_GOOGLE_PROJECT: '1051331554757',
    STORAGE_APP: 'rhparking-app-develop',
    VERSION_APP: 10013,
    VERSION_APP_STRING: '1.0.13.1',
};
