import { Injectable } from '@angular/core';
import { ActivatedRoute, ActivatedRouteSnapshot } from '@angular/router';
import { Storage } from '@capacitor/storage';
import { UrlViewerModalComponent } from '@components/url-viewer/url-viewer-modal.component';
import { PreviewAnyFile } from '@ionic-native/preview-any-file/ngx';
import { ModalController } from '@ionic/angular';
import { httpData } from '@models/data.model';
import { UserModel } from '@models/user.model';
import { TranslateService } from '@ngx-translate/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { environment } from '../../environments/environment';
import { SkinThemeFeesModel } from '../models/theme.model';
import { ApiService } from './api.service';
import { CardsService } from './cards.service';
import { ThemeService } from './theme.service';
import { UtilService } from './util.service';

@Injectable({
    providedIn: 'root',
})
export class UserService {
    private subs = new SubSink();

    private _authenticationState: BehaviorSubject<boolean>;
    public authenticationState: Observable<boolean>;
    private _logoutAction: BehaviorSubject<{ state: boolean; redirect: boolean }>;
    public logoutAction: Observable<{ state: boolean; redirect: boolean }>;
    private _info: BehaviorSubject<UserModel>;
    public info: Observable<UserModel>;

    private dataStore: {
        info: UserModel;
    };

    constructor(
        private previewAnyFile: PreviewAnyFile,
        private modalController: ModalController,
        private cardsService: CardsService,
        private themeService: ThemeService,
        private util: UtilService,
        private api: ApiService,
        private translate: TranslateService
    ) {
        //Storage.migrate();
        this.dataStore = {
            info: new UserModel(),
        };
        this._info = new BehaviorSubject(new UserModel());
        this.info = this._info.asObservable();
        this._authenticationState = new BehaviorSubject(false);
        this.authenticationState = this._authenticationState.asObservable();
        this._logoutAction = new BehaviorSubject({ state: false, redirect: false });
        this.logoutAction = this._logoutAction.asObservable();
    }

    public logout = (redirect: boolean = true) => {
        this._authenticationState.next(false);
        this._logoutAction.next({ state: true, redirect: redirect });
        this.userReset();
        return true;
    };

    public isAuthenticated = () => {
        return this._authenticationState.value;
    };

    public hasDebt = () => {
        return this.dataStore.info.hasDebt;
    };

    public getToken(): string {
        return this.dataStore.info.accessToken;
    }

    public getClientId(): string {
        return this.dataStore.info.clientId;
    }

    public getInfo(): UserModel {
        return { ...{}, ...this.dataStore.info };
    }

    public userUpdate = (data: any) => {
        for (let key in data) {
            if (data.hasOwnProperty(key) && typeof this.dataStore.info[key] != 'undefined') {
                this.dataStore.info[key] = data[key];
            }
        }
        this._info.next(Object.assign({}, this.dataStore).info);
    };

    public checkToken(): Promise<boolean> {
        let deferrer = new Promise<boolean>((resolve) => {
            console.log('CHECK TOKEN', `${environment.STORAGE_APP}-token`);
            Storage.get({ key: `${environment.STORAGE_APP}-token` }).then((data) => {
                console.log('CHECK TOKEN', data);
                if (data.value) {
                    this.dataStore.info.accessToken = data.value;
                    this._info.next(Object.assign({}, this.dataStore).info);
                    Storage.get({ key: `${environment.STORAGE_APP}-theme` }).then((client) => {
                        console.log('CHECK CLIENT', client);
                        if (client.value) {
                            this.dataStore.info.clientId = client.value;
                            this._info.next(Object.assign({}, this.dataStore).info);
                        }
                        this.connect()
                            .pipe(take(1))
                            .subscribe(
                                (data) => {
                                    resolve(this._authenticationState.value);
                                },
                                (err) => {
                                    resolve(this._authenticationState.value);
                                }
                            );
                    });
                } else {
                    resolve(this._authenticationState.value);
                }
            });
        });
        return deferrer;
    }

    public connect = (accessData: string = 'startup'): Observable<any> => {
        return this.api.get('user', {}).pipe(
            map((data: httpData) => {
                for (let key in data.data) {
                    if (data.data.hasOwnProperty(key) && typeof this.dataStore.info[key] != 'undefined') {
                        this.dataStore.info[key] = data.data[key];
                    }
                }
                console.log('user info', this.dataStore.info);
                this._authenticationState.next(true);
                this._logoutAction.next({ state: false, redirect: false });
                Storage.set({ key: `${environment.STORAGE_APP}-token`, value: this.dataStore.info.accessToken });
                Storage.set({ key: `${environment.STORAGE_APP}-theme`, value: this.dataStore.info.clientId });
                this.themeService.setTheme(this.dataStore.info.clientId);
                this._info.next(Object.assign({}, this.dataStore).info);
                this.getSettings();
                this.subs.unsubscribe();
                this.subs.add(
                    this.cardsService.cards.subscribe((data) => {
                        this.dataStore.info.cards = data;
                        this._info.next(Object.assign({}, this.dataStore).info);
                    }),
                    this.themeService.theme.subscribe((theme) => {
                        if (theme.clientId != this.dataStore.info.clientId) {
                            console.log(
                                '*************************** CHANGE USER THEME FROM USER SERVICE ***************************',
                                theme.clientId,
                                this.dataStore.info.clientId
                            );
                            this.updateClientUser({ clientId: theme.clientId });
                        }
                    })
                );
                this.cardsService.getCards();

                //sacamos el toast de aviso de tiempo para validar email
                let _arrayNotAsk = ['login', 'register', 'profile'];
                if (!data.data.verified && _arrayNotAsk.indexOf(accessData) == -1) {
                    if (data.data.remainingTime > 0) {
                        this.alertEmailNoValidate(data.data.remainingTime);
                    }
                }
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public refresh = (): Observable<any> => {
        return this.api.get('user/data', {}).pipe(
            map((data: httpData) => {
                for (let key in data.data) {
                    if (data.data.hasOwnProperty(key) && typeof this.dataStore.info[key] != 'undefined') {
                        this.dataStore.info[key] = data.data[key];
                    }
                }
                console.log('user info', this.dataStore.info);
                this._info.next(Object.assign({}, this.dataStore).info);
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public alertEmailNoValidate = (time: number) => {
        let timeTemp = Math.floor(time / 1000);
        let days = Math.floor(timeTemp / 60 / 60 / 24);
        timeTemp = timeTemp - Math.floor(days * 24 * 60 * 60);
        let hours = Math.floor(timeTemp / 60 / 60);
        timeTemp = timeTemp - Math.floor(hours * 60 * 60);
        let minutes = Math.floor(timeTemp / 60);
        let seconds = Math.floor(timeTemp - Math.floor(minutes * 60));
        let text = '';
        if (days > 0) {
            let _letter = this.translate.instant('common.days');
            text = `${text}${days} ${_letter}`;
        } else if (hours > 0) {
            let _letter = this.translate.instant('common.hours');
            text = `${text}${hours} ${_letter}`;
        } else if (minutes > 0) {
            let _letter = this.translate.instant('common.minutes');
            text = `${text}${minutes} ${_letter}`;
        } else if (seconds > 0) {
            let _letter = this.translate.instant('common.seconds');
            text = `${text}${seconds} ${_letter}`;
        }
        this.util
            .alertButtons({
                message: 'common.messages.alert.email_no_validate',
                paramsTexts: { time: text },
                buttons: [
                    { text: 'common.send_again', role: 'primary' },
                    { text: 'common.close', role: 'cancel' },
                ],
            })
            .then((data) => {
                if (data) {
                    if (data.button === 0) {
                        this.resendEmailValidate();
                    }
                }
            });
    };

    private resendEmailValidate = (dataSend: any = {}): Promise<any> => {
        return this.api
            .post('user/email/verification/resend', dataSend)
            .pipe(
                take(1),
                map((data: httpData) => {
                    return data.data;
                }),
                catchError(() => {
                    return [];
                })
            )
            .toPromise();
    };

    public register = (dataSend: any = {}): Observable<any> => {
        return this.api.post('register', dataSend).pipe(
            map((data: httpData) => {
                if (data.data.canAccess) {
                    this.dataStore.info.accessToken = data.data.accessToken;
                    this.dataStore.info.clientId = data.data.clientId;
                    this._info.next(Object.assign({}, this.dataStore).info);
                }
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public updateClientUser = (dataSend: any = {}): Promise<any> => {
        this.dataStore.info.clientId = dataSend.clientId;
        return this.api
            .post('user/edit/client', dataSend)
            .pipe(
                map((data: httpData) => {
                    return data.data;
                }),
                catchError((error) => {
                    return [];
                })
            )
            .toPromise();
    };

    public setFullSignupAnswer = (dataSend: any = {}): Promise<any> => {
        return this.api
            .post('user/edit/fullSignup/answer', dataSend)
            .pipe(
                map((data: httpData) => {
                    this.dataStore.info.fullSignupAnswer = dataSend.fullSignupAnswer;
                    return data.data;
                }),
                catchError((error) => {
                    return [];
                })
            )
            .toPromise();
    };

    public checkClientUser = (dataSend: any = {}): Observable<any> => {
        return this.api.post('check', dataSend).pipe(
            map((data: httpData) => {
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public login = (dataSend: any = {}): Observable<any> => {
        return this.api.post(`login`, dataSend).pipe(
            map((data: httpData) => {
                if (data.data.canAccess) {
                    this.dataStore.info.accessToken = data.data.accessToken;
                    this.dataStore.info.hashid = data.data.userId;
                    this.dataStore.info.clientId = data.data.clientId;
                    this._info.next(Object.assign({}, this.dataStore).info);
                }
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public edit = (dataSend: any = {}): Observable<any> => {
        return this.api.post('user/edit', dataSend).pipe(
            map((data: httpData) => {
                for (let key in data.data) {
                    if (data.data.hasOwnProperty(key) && typeof this.dataStore.info[key] != 'undefined') {
                        this.dataStore.info[key] = data.data[key];
                    }
                }
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public changePassword = (dataSend: any = {}): Observable<any> => {
        return this.api.post('user/password/change ', dataSend).pipe(
            map((data: httpData) => {
                this.dataStore.info.accessToken = data.data.accessToken;
                this._info.next(Object.assign({}, this.dataStore).info);
                return true;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public recoverPassword = (dataSend: any = {}): Observable<any> => {
        return this.api.post('password/recovery', dataSend).pipe(
            map((data: httpData) => {
                return true;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public canAutopay = (dataSend: any = {}): Observable<any> => {
        return this.api.get(`canAutopay`, dataSend).pipe(
            map((data: httpData) => {
                return data.data.canAutopay;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public hasAutopay = (dataSend: any = {}): Observable<any> => {
        return this.api.get('hasAutopay', dataSend).pipe(
            map((data: httpData) => {
                return data.data.autopayVehicles;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public getUserDebt = (dataSend: any = {}): Observable<any> => {
        return this.api.get(`debt/check`, dataSend).pipe(
            map((data: httpData) => {
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public getSettings = (): void => {
        this.api
            .get('settings', {})
            .pipe(
                take(1),
                map((data: httpData) => {
                    this.dataStore.info.settings = data.data;
                    this._info.next(Object.assign({}, this.dataStore).info);
                    return true;
                }),
                catchError((error) => {
                    return this.util.apiErrorReturn(error);
                })
            )
            .subscribe();
    };

    public editSettings = (dataSend: any): Observable<any> => {
        return this.api.post('settings/edit', dataSend).pipe(
            map((data: httpData) => {
                this.getSettings();
                return true;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public getSession = (sid: string): Observable<any> => {
        return this.api.getWithOutVersion(`s?sid=${sid}`, {}).pipe(
            map((data: httpData) => {
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public clearStorage = async () => {
        await Storage.remove({ key: `${environment.STORAGE_APP}-token` });
        await Storage.remove({ key: `${environment.STORAGE_APP}-theme` });
        console.log('clearStorage', `${environment.STORAGE_APP}-token`);
    };

    private userReset() {
        this.clearStorage();
        this.dataStore.info = new UserModel();
        this.subs.unsubscribe();
        this.cardsService.resetCards();
        this._info.next(Object.assign({}, this.dataStore).info);
    }

    async openLegal(type: string, pdfRoute: string) {
        if (pdfRoute != '') {
            if (this.util.inApp()) {
                this.util.startLoading({ texts: ['common.loading'], config: { spinner: 'dots' } });
                this.previewAnyFile
                    .preview(pdfRoute)
                    .then((res: any) => {
                        this.util.stopLoading();
                    })
                    .catch((error: any) => {
                        this.util.stopLoading();
                    });
            } else {
                let _file = this.util.inSafariMobile()
                    ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(pdfRoute)}`
                    : pdfRoute;
                const modal = await this.modalController.create({
                    component: UrlViewerModalComponent,
                    backdropDismiss: false,
                    cssClass: 'url-viewer-modal-style',
                    componentProps: {
                        titleTx: `menu.labels.${type}`,
                        urlIframe: _file,
                    },
                });
                modal.present();
            }
        }
    }

    async openFees(fee: SkinThemeFeesModel) {
        switch (fee.type) {
            case 0:
                if (fee.url.length > 0) window.open(fee.url, '_system', 'location=yes');
                break;
            case 1:
                if (fee.file.length > 0) {
                    if (this.util.inApp()) {
                        this.util.startLoading({ texts: ['common.loading'], config: { spinner: 'dots' } });
                        this.previewAnyFile
                            .preview(fee.file)
                            .then((res: any) => {
                                this.util.stopLoading();
                            })
                            .catch((error: any) => {
                                this.util.stopLoading();
                            });
                    } else {
                        let _file = this.util.inSafariMobile()
                            ? `https://drive.google.com/viewerng/viewer?embedded=true&url=${encodeURIComponent(
                                  fee.file
                              )}`
                            : fee.file;
                        const modal = await this.modalController.create({
                            component: UrlViewerModalComponent,
                            backdropDismiss: false,
                            cssClass: 'url-viewer-modal-style',
                            componentProps: {
                                titleTx: fee.name,
                                urlIframe: _file,
                            },
                        });
                        modal.present();
                    }
                }
                break;
        }
    }

    public verifyLocalAction = (route: ActivatedRoute) => {
        return new Promise((resolve) => {
            let localSave = this.hasLocalSave();
            if (localSave) {
                if (localStorage.getItem('actionType'))
                    this.dataStore.info.action.type = localStorage.getItem('actionType');
                if (localStorage.getItem('actionValue'))
                    this.dataStore.info.action.value = localStorage.getItem('actionValue');
                if (localStorage.getItem('actionToken'))
                    this.dataStore.info.action.token = localStorage.getItem('actionToken');
                if (localStorage.getItem('actionClient'))
                    this.dataStore.info.action.client = localStorage.getItem('actionClient');
            } else {
                if (window['_APP_CONFIG']) {
                    if (window['_APP_CONFIG'].hasOwnProperty('actionType'))
                        this.dataStore.info.action.type = window['_APP_CONFIG'].actionType;
                    if (window['_APP_CONFIG'].hasOwnProperty('actionValue'))
                        this.dataStore.info.action.value = window['_APP_CONFIG'].actionValue;
                    if (window['_APP_CONFIG'].hasOwnProperty('actionToken'))
                        this.dataStore.info.action.token = window['_APP_CONFIG'].actionToken;
                    if (window['_APP_CONFIG'].hasOwnProperty('actionClient'))
                        this.dataStore.info.action.client = window['_APP_CONFIG'].actionClient;
                }
            }

            this._info.next(Object.assign({}, this.dataStore).info);

            if (this.dataStore.info.action.token != '') {
                resolve({ error: false, type: this.dataStore.info.action.type });
            } else {
                resolve({ error: true });
            }
        });
    };

    public getSessionData = (route: ActivatedRouteSnapshot) => {
        return new Promise((resolve) => {
            let sid = route.queryParamMap.get('sid') ?? '';
            let localSave = this.hasLocalSave();
            if (sid) {
                this.getSession(sid)
                    .pipe(take(1))
                    .subscribe(
                        (data) => {
                            if (localSave) {
                                localStorage.setItem('actionType', data.AppActionType);
                                localStorage.setItem('actionValue', data.AppActionValue);
                                localStorage.setItem('actionToken', data.AppActionToken);
                                localStorage.setItem('actionClient', data.AppActionClient);
                            } else {
                                window['_APP_CONFIG'] = {
                                    actionType: data.AppActionType,
                                    actionValue: data.AppActionValue,
                                    actionToken: data.AppActionToken,
                                    actionClient: data.AppActionClient,
                                };
                            }
                            resolve({ error: false, type: data.AppActionType });
                        },
                        (err) => {
                            resolve({ error: true });
                        }
                    );
            } else {
                let type = '';
                if (localSave) {
                    type = localStorage.getItem('actionType');
                } else {
                    if (window['_APP_CONFIG']) {
                        type = window['_APP_CONFIG'].actionType;
                    }
                }
                if (type != '') {
                    resolve({ error: false, type: type });
                }
                resolve({ error: true });
            }
        });
    };

    public clearActionStorage = () => {
        let localSave = this.hasLocalSave();
        if (localSave) {
            localStorage.removeItem('actionType');
            localStorage.removeItem('actionValue');
            localStorage.removeItem('actionToken');
            localStorage.removeItem('actionClient');
        } else {
            delete window['_APP_CONFIG'];
        }
    };

    public hasLocalSave = () => {
        let localSave = false;
        let testKey = 'test',
            storage = window.localStorage;
        try {
            storage.setItem(testKey, '1');
            storage.removeItem(testKey);
            localSave = true;
        } catch (error) {
            localSave = false;
        }
        return localSave;
    };

    public restorePassword = (dataSend: any = {}): Observable<any> => {
        return this.api.post(`password/restore`, dataSend).pipe(
            map((data: httpData) => {
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public getAddresses = (dataSend: any = {}): Observable<any> => {
        return this.api.get(`user/addresses`, dataSend).pipe(
            map((data: httpData) => {
                this.dataStore.info.addresses = data.data;
                this._info.next(Object.assign({}, this.dataStore).info);
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public deleteAddress = (dataSend: any = {}): Observable<any> => {
        return this.api.post(`user/addresses/delete`, dataSend).pipe(
            map((data: httpData) => {
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public editAddress = (path: string = 'create', dataSend: any = {}): Observable<any> => {
        return this.api.post(`user/addresses/${path}`, dataSend).pipe(
            map((data: httpData) => {
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };

    public deleteAccount = (dataSend: any = {}): Observable<any> => {
        return this.api.post(`user/delete`, dataSend).pipe(
            map((data: httpData) => {
                return data.data;
            }),
            catchError((error) => {
                return this.util.apiErrorReturn(error);
            })
        );
    };
}
