import { Injectable } from '@angular/core';
import { CanActivate, Router } from '@angular/router';
import { UserService } from '@services/user.service';

@Injectable({
    providedIn: 'root'
})
export class ActiveGuard implements CanActivate {

    constructor(
        private user: UserService,
        private router: Router
    ) { }

    async canActivate():Promise<boolean> {
        if(this.user.isAuthenticated()){
            this.router.navigate(['/'], {replaceUrl:true});
            return false;
        }else{
            let _auth = await this.user.checkToken();
            if(_auth){
                this.router.navigate(['/'], {replaceUrl:true});
                return false;
            }else{
                return true;
            }
        }
    }

}
