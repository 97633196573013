import { Injectable, ElementRef } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';
import { catchError, map } from 'rxjs/operators';
import { environment } from '../../environments/environment';
import { httpData } from '@models/data.model';
import { SkinThemeFeesModel, ThemeModel } from '@models/theme.model';
import { ApiService } from './api.service';
import { UtilService } from './util.service';

import { Storage } from '@capacitor/storage';

@Injectable({
  	providedIn: 'root'
})
export class ThemeService {

	private _theme: BehaviorSubject<ThemeModel>;
	public theme: Observable<ThemeModel>;
	private fees: BehaviorSubject<SkinThemeFeesModel[]>;
	public $fees: Observable<SkinThemeFeesModel[]>;

	private dataStore: {
		theme:ThemeModel,
		themes:ThemeModel[]
	};

	constructor(
		private util:UtilService,
		private api:ApiService
	) {
		//Storage.migrate();
		this.dataStore = {
			theme:null,
			themes:[]
		}
		this._theme = new BehaviorSubject(null);
		this.theme = this._theme.asObservable();
		this.fees = new BehaviorSubject([]);
		this.$fees = this.fees.asObservable();
	}

	public loadThemes = (dataSend:any = {}): Observable<any> => {
        return this.api.get('clients', dataSend).pipe(
            map( (data:httpData) => {
				this.dataStore.themes = data.data.map((item:any) => {
					let _skinTheme = JSON.parse(item.skin.theme);
					item.skin.theme = _skinTheme;
					if(item.skin.modules && item.skin.modules != ''){
						let _skinModules = JSON.parse(item.skin.modules);
						item.skin.modules = _skinModules;
					}else{
						item.skin.modules = {};
					}
					return item;
				});
				console.log('**** clients', this.dataStore.themes);
                return true;
            }),
            catchError( error => {return this.util.apiErrorReturn(error);})
        );
	}

	public getFees = async () => {
		try {
			let feesRequest = await this.api.get('client/fees', {}).toPromise() as httpData;
			return feesRequest.data;
		} catch (error) {
			return [];
		}
	}

	public setTheme = (themeId:string) => {
		this.dataStore.theme = this.dataStore.themes.find(item => item.clientId == themeId);
		if(this.dataStore.theme){
			this._theme.next(Object.assign({}, this.dataStore).theme);
			Storage.set({key:`${environment.STORAGE_APP}-theme`, value:this.dataStore.theme.clientId});
		}else{
			this.setDefaultTheme();
		}
	}

	public changeTheme = (elementRef:ElementRef) => {
		for (const property in this.dataStore.theme.skin.theme.colors) {
			elementRef.nativeElement.style.setProperty(`${property}`,this.dataStore.theme.skin.theme.colors[property]);
			document.body.style.setProperty(`${property}`,this.dataStore.theme.skin.theme.colors[property]);
		}
	}

	public setDefaultTheme = () => {
		if(environment.clientId != ''){
			this.dataStore.theme = this.dataStore.themes.find(item => item.clientId == environment.clientId);
			if(this.dataStore.theme){
				this._theme.next(Object.assign({}, this.dataStore).theme);
				Storage.set({key:`${environment.STORAGE_APP}-theme`, value:this.dataStore.theme.clientId});
			}
		}else{
			this.dataStore.theme = this.dataStore.themes.find(item => item.isDefault);
			this._theme.next(Object.assign({}, this.dataStore).theme);
			Storage.set({key:`${environment.STORAGE_APP}-theme`, value:this.dataStore.theme.clientId});
		}
	}

	public getThemes = () => {
		return this.dataStore.themes.map(item => {return item});
	}

	public getTheme = () => {
		return {...{},...this.dataStore.theme};
	}

	public getThemeByCode = (code:number) => {
		return this.api.post('access', {accessCode:code}).pipe(
            map( (data:httpData) => {
				let _client:ThemeModel = data.data;
				let _verifyClient = this.dataStore.themes.find(item => item.clientId == _client.clientId);
                return _verifyClient;
            }),
            catchError( error => {return this.util.apiErrorReturn(error);})
        );
	}

	public canChangeClient = () => {
		if(this.dataStore.themes.length > 1){
			return true;
		}
		return false;
	}

}
