import { Injectable } from '@angular/core';
import { Router, CanActivate, RouterStateSnapshot, ActivatedRouteSnapshot, UrlTree } from '@angular/router';
import { StartupService } from '@services/startup.service';
import { Observable } from 'rxjs';

@Injectable({
    providedIn: 'root',
})
export class VersionGuard implements CanActivate {
    constructor(private startupService: StartupService, private router: Router) {}

    canActivate(
        next: ActivatedRouteSnapshot,
        state: RouterStateSnapshot
    ): Observable<boolean | UrlTree> | Promise<boolean | UrlTree> | boolean | UrlTree {
        console.log('version Guard', state);
        if (state.url === '/update') {
            if (this.startupService.updateApp()) {
                return true;
            } else {
                this.router.navigate(['/'], { replaceUrl: true });
                return false;
            }
        } else {
            if (this.startupService.updateApp()) {
                this.router.navigate(['/update'], { replaceUrl: true });
                return false;
            } else {
                return true;
            }
        }
    }
}
