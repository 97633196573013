import { NgModule } from '@angular/core';
import { SharedModule } from '../../config/shared.module';

import { PdfViewerModalComponent } from './pdf-viewer-modal/pdf-viewer-modal.component';
import { NgxExtendedPdfViewerModule } from 'ngx-extended-pdf-viewer';
import { PipesModule } from '../../pipes/pipes.module';
import { HeaderModule } from '../header/header.component.module';

@NgModule({
	declarations: [
		PdfViewerModalComponent
	],
	imports: [
		SharedModule,
		PipesModule,
		HeaderModule,
		NgxExtendedPdfViewerModule
	],
	exports: [
		PdfViewerModalComponent
	],
})
export class PdfViewerModule { }
