import { Injectable, NgZone } from '@angular/core';
import { OneSignal, OSNotification } from '@ionic-native/onesignal/ngx';
import { take } from 'rxjs/operators';
import { ApiService } from './api.service';
import { UtilService } from './util.service';
import { Router } from '@angular/router';
import { NotificationsService } from './notifications.service';
import { Badge } from '@ionic-native/badge/ngx';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';

@Injectable({
    providedIn: 'root'
})
export class PushService {

    private pushId:string;
	private initOneSignal:boolean;

    constructor(
		private user:UserService,
		private ngZone:NgZone,
		private badge: Badge,
		private util:UtilService,
		private router:Router,
		private api:ApiService,
		private oneSignal:OneSignal,
		private notificationsService:NotificationsService
    ) {
		this.pushId = '';
		this.initOneSignal = false;
    }

	async getPushId() {
		if(this.util.inApp()){
			const info = await this.oneSignal.getIds();
            return info.userId;
		}else{
			return '';
		}
	}
    public activatePushUser = () => {
		this.getPushId().then(data => {
			if(data != ''){
				this.pushId = data;
				this.api.post(`user/edit/push/add`,{pushId:this.pushId}).pipe(take(1)).subscribe();
				this.oneSignal.setExternalUserId(this.user.getInfo().hashid);
			}
		});
	}
    public clearPushUser = () => {
        if(this.pushId && this.pushId != ''){
            this.api.post(`user/edit/push/clear`,{pushId:this.pushId}).pipe(take(1)).subscribe();
            this.pushId = '';
            this.notificationsService.clearStoragePush();
        }
		this.oneSignal.removeExternalUserId();
	}

	public initConfig = () => {
        if(this.util.inApp()){
            this.oneSignal.startInit(environment.ONESIGNAL_ID, environment.ONESIGNAL_GOOGLE_PROJECT);

			this.oneSignal.iOSSettings({
				kOSSettingsKeyAutoPrompt:false,
				kOSSettingsKeyInAppLaunchURL: false
			});

			//this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.None); //=> no muestra nada si la app está abierta
            this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.Notification); //=> muestra notificación cuando esta en la app
            //this.oneSignal.inFocusDisplaying(this.oneSignal.OSInFocusDisplayOption.InAppAlert); //=> muestra alert cuando esta en la app

            this.oneSignal.handleNotificationReceived().subscribe((data) => {
				console.log('Notification Received!!!',data);
                this.addPush(data,'received');
            });

            this.oneSignal.handleNotificationOpened().subscribe((data) => {
				console.log('Notification Opened!!!',data);
				this.addPush(data.notification,'opened');
            });

            this.oneSignal.endInit();
			this.initOneSignal = true;
        }
	}

	async addPush(notification:OSNotification,type:string){
        const payload = notification.payload;
		if(payload && payload.additionalData && payload.additionalData.hasOwnProperty('type')){
			switch(payload.additionalData.type){
				case 'notification':
					setTimeout(()=>{
						//actualizar las notificaciones
						this.notificationsService.setNewNotificationPush(notification);
						if(this.user.isAuthenticated()){
							this.notificationsService.getPending();
						}
						switch(type){
							case 'received':
								if(notification.isAppInFocus){
									this.badge.clear();
								}
							break;
							case 'opened':
								//abrir las notificaciones
								this.ngZone.run(async () =>{
									this.router.navigate([`notifications`],{replaceUrl:true});
									this.badge.clear();
								})
							break;
						}
					});
				break;
			}
		}
	}

	public async promptPermission(){
		let _perms = await this.oneSignal.promptForPushNotificationsWithUserResponse();
		return _perms;
	}

}