import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment';
import { ThemeService } from './theme.service';
import { take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { ApiService } from './api.service';
import { httpData } from '@models/data.model';
import { UtilService } from './util.service';

import { Storage } from '@capacitor/storage';

@Injectable({
  	providedIn: 'root'
})
export class StartupService {

	private minVersion:number;

	constructor(
		private util:UtilService,
		private api:ApiService,
		private translate: TranslateService,
		private themeService:ThemeService
	) {
		//Storage.migrate();
		this.minVersion = environment.VERSION_APP;
	}

	public updateApp = () => {
		return (this.util.inApp() && (environment.VERSION_APP < this.minVersion));
	}

	public load():Promise<any> {
		console.log('Startup');
        return new Promise<any>((resolve) => {
			this.translate.setDefaultLang('en');
			this.translate.use('en');
			this.util.loadDeviceInfo().then(() => {
				this.api.getVersion().pipe(take(1)).subscribe(
					(data:httpData) => {
						this.minVersion = data.data.latest;
						this.themeService.loadThemes().pipe(take(1)).subscribe(
							response =>{
								if(environment.clientId != ''){
									this.themeService.setDefaultTheme();
									resolve(true);
								}else{
									Storage.get({key:`${environment.STORAGE_APP}-theme`}).then(data => {
										console.log('Storage',data);
										if(data.value && typeof(data.value) != 'undefined' && data.value != 'undefined'){
											console.log('THEME SAVE', data.value);
											this.themeService.setTheme(data.value);
										}else{
											this.themeService.setDefaultTheme();
										}
										resolve(true);
									})
								}
							},
							err => {
								resolve(true);
							}
						)
					},
					err => {
						resolve(true)
					}
				)
			});
        })
    }

}
