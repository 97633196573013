import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, RouterStateSnapshot } from '@angular/router';
import { UserService } from '../services/user.service';

@Injectable({
  providedIn: 'root'
})
export class LinksResolveService implements Resolve<any> {

    constructor(
        private user: UserService
    ) { }

    async resolve(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {
        console.log("LinksResolveService");
        let _auth: any = await this.user.getSessionData(route);
        let arrayAccess = ['restore-password', 'drop-off-password', 'email-verified'];
        if(!_auth.error && (arrayAccess.indexOf(_auth.type) > -1)) {
            return _auth.type;
        }else{
            return 'not-found';
        }
    }
}
