import { NgModule } from '@angular/core';
import { SharedModule } from '@config/shared.module';
import { PipesModule } from '@pipes/pipes.module';
import { HeaderModule } from '../header/header.component.module';

import { UrlViewerModalComponent } from './url-viewer-modal.component';

@NgModule({
	declarations: [
		UrlViewerModalComponent
	],
	imports: [
		SharedModule,
		PipesModule,
		HeaderModule,
	],
	exports: [
		UrlViewerModalComponent
	]
})
export class UrlViewerModule { }
