import { NgModule } from '@angular/core';
import { CapitalizePipe } from './capitalize/capitalize.pipe';
import { EllipsisPipe } from './ellipsis/ellipsis.pipe';
import { SanitizeHtmlPipe } from './sanitize/sanitize-html.pipe';
import { SanitizeUrlPipe } from './sanitize/sanitize-url.pipe';
import { SafeUrlPipe } from './safe/safe-url.pipe';
import { FormatNumberPipe } from './format-number/format-number.pipe';
import { FormatDateMomentPipe } from './format-date-moment/format-date-moment.pipe';
import { MessageChatPipe } from './message-chat/message-chat.pipe';

@NgModule({
    declarations: [
        CapitalizePipe,
        EllipsisPipe,
        SanitizeHtmlPipe,
        SanitizeUrlPipe,
		SafeUrlPipe,
		MessageChatPipe,
		FormatNumberPipe,
		FormatDateMomentPipe,
    ],
    exports: [
        CapitalizePipe,
        EllipsisPipe,
        SanitizeHtmlPipe,
        SanitizeUrlPipe,
		SafeUrlPipe,
		MessageChatPipe,
		FormatNumberPipe,
		FormatDateMomentPipe,
    ]
})
export class PipesModule { }
