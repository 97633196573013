import { Injectable } from '@angular/core';
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpEventType, HttpResponse } from '@angular/common/http';
import { environment } from '../../environments/environment';
import { Observable, Subject, throwError } from 'rxjs';
import { NetworkService } from './network.service';

@Injectable({
  	providedIn: 'root'
})
export class ApiService {

	constructor(private http:HttpClient,private network:NetworkService) { }

	public getVersion = () => {
		return this.http.get(`${environment.PATH_API}version/${environment.VERSION_APP_STRING}`);
	}

	public post = (path:string,data:any) => {
        let body = JSON.stringify(data);
		return this.http.post(`${environment.PATH_API}${environment.VERSION_API}${path}`,body);
	}

	public get = (path:string,data:any) => {
		return this.http.get(`${environment.PATH_API}${environment.VERSION_API}${path}`, {params:data});
	}

	public getWithOutVersion = (path:string,data:any) => {
		return this.http.get(`${environment.PATH_API}${path}`, {params:data});
	}

	public handleError(error) {
        let errorMessage = 501;
        if (error instanceof HttpErrorResponse) {
            switch(error.status){
                case 401: //Unauthorized
				case 403: //Insufficient privileges
				case 405: //Method Not Allowed
                    errorMessage = error.status;
                break;
                case 400: //Api errors
                    errorMessage = error.error.errorNo || 501;
                break;
            }
		}
		if(errorMessage == 501 && !this.network.isOnline()){
			errorMessage = 502;
		}
        return errorMessage;
	}

	public uploadFile(path:string,file:File,data?:any) {
		const formData: FormData = new FormData();
		formData.append('file',file);
		if(typeof(data) != 'undefined'){
			for(let key in data){
				formData.append(key, data[key]);
			}
		}
		return this.http.post<any>(`${environment.PATH_API}${environment.VERSION_API}${path}`, formData, {
			headers:new HttpHeaders({'X-File':'file'})
		});
	}

	public uploadFiles(path:string,files:Set<File>,data?:any):{[key: string]:{progress:Observable<number>}} {
        const status: {[key: string]:{progress: Observable<number>}} = {};
        files.forEach(file => {
            const formData: FormData = new FormData();
			formData.append('file',file);
			if(data){
				for(let key in data){
					formData.append(key, data[key]);
				}
			}
            const progress = new Subject<number>();
            this.http.post<any>(`${environment.PATH_API}${environment.VERSION_API}${path}`, formData, {
                headers:new HttpHeaders({'X-File':'file'}),
                reportProgress: true,
                observe: 'events'
            }).subscribe(event => {
                if (event.type === HttpEventType.UploadProgress) {
                    const percentDone = Math.round(100 * event.loaded / event.total);
                    progress.next(percentDone);
                } else if (event instanceof HttpResponse) {
                    progress.complete();
                }
            });
            status[file.name] = {
                progress: progress.asObservable()
            };
        });
        return status;
	}


	public downloadFile = (path:string,dataSend:any = {}):Promise<any> => {
		return new Promise(resolve => {
			this.http.post(`${environment.PATH_API}${environment.VERSION_API}${path}`,dataSend,{
				headers:new HttpHeaders({'X-File':'file'}),
				responseType: 'blob' as 'json',
				observe: 'response'
			}).subscribe(
				async(response: any) =>{
					console.log(response);
					let name = response.headers.get('File-Name');
					let file = response.body;
					resolve({error:false, name:name, file:file});
				},
				(error) => {
					throwError(error);
					resolve({error:true});
				}
			)
		})
	}
}
