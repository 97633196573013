import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  	name: 'ellipsis'
})
export class EllipsisPipe implements PipeTransform {

	transform(value: any, args?: any): any {
		let valReturn = value;
        if(typeof(args) != 'undefined'){
			if(value.length > args){
				valReturn = value.substring(0, args) + '...';
			}
			let index = valReturn.indexOf('<br>');
			if(index > -1){
				valReturn = value.substring(0,index) + '...';
			}
		}
		return valReturn;
    }

}
