import { Injectable } from '@angular/core';
import { AbstractControl, ValidatorFn, FormControl, Validators } from '@angular/forms';
import * as libphonenumber from 'google-libphonenumber';

@Injectable({
    providedIn: 'root'
})
export class ValidatorsService {}
export class ValidatorMessages {
    static get = (control:string):string => {
        let _errorMsg = '';
        switch(control){
            case "required":
                _errorMsg = 'common.error.required';
			break;
			case "pattern":
                _errorMsg = 'common.error.pattern';
			break;
			case "alphanumeric":
                _errorMsg = 'common.error.alphanumeric';
            break;
            case "whitespace":
                _errorMsg = 'common.error.whitespace';
			break;
			case "invalidUsername":
                _errorMsg = 'common.error.username';
            break;
            case "invalidPhone":
                _errorMsg = 'common.error.phone-number';
            break;
            case "invalidEmail":
                _errorMsg = 'common.error.email';
            break;
            case "invalidCp":
                _errorMsg = 'common.error.cp';
            break;
            case "invalidUrl":
                _errorMsg = 'common.error.url';
            break;
            case "min":
            case "max":
                _errorMsg = 'common.error.number_range';
            break;
            case "requiredCheck":
				_errorMsg = 'common.error.required';
			break;
			case "identification":
                _errorMsg = 'common.error.identification';
            break;
		}
        return _errorMsg;
    }
}
export class ValidatorsCustom {

	static username = (control: FormControl) => {
		if(!control.value) return null;
        const USERNAME_REGEXP = /^@[0-9a-zA-Z]*$/;
		let v:string = control.value.trim();
		if(v == '@') return {required: true};
        return USERNAME_REGEXP.test(v) ? null : {invalidUsername: true};
	};

	static alphanumeric = (control: FormControl) => {
        if(!control.value) return null;
		const ALPHANUMERIC_REGEXP = /^[0-9a-zA-Z]*$/;
		let v:string = control.value.trim();
		return ALPHANUMERIC_REGEXP.test(v) ? null : {alphanumeric: true};
    };

	static requiredIfValidator(predicate) {
		return (formControl => {

			if (!formControl.parent) {
				return null;
			}
			if (predicate()) {
				console.log('requiredIfValidator',predicate());
				return Validators.required(formControl);
			}
			return null;
		})
	}

    private isPresent(obj: any): boolean {
        return obj !== undefined && obj !== null;
    }

    private isDate(obj: any): boolean {
        return !/Invalid|NaN/.test(new Date(obj).toString());
    }

    static noWhitespace = (control: FormControl) => {
        if(control.value && control.value.length > 0){
            const isWhitespace = (control.value || '').trim().length === 0;
            const isValid = !isWhitespace;
            return isValid ? null : {whitespace:true};
        }else{
            return null;
        }
    };

    static isChecked = (control: FormControl) => {
        return control.value ? null : {requiredCheck: true};
    }

    static phone = (countryControl:string[] = ['ES']): ValidatorFn => {
        return (phoneControl: AbstractControl): {[key: string]: boolean} => {
            if(phoneControl.value !== ""){
                try{
					const phoneUtil = libphonenumber.PhoneNumberUtil.getInstance();
					let isValidNumber = false;
					countryControl.forEach(item => {
						if(!isValidNumber){
							let phoneNumber = "" + phoneControl.value + "";
							let region = item;
							let number = phoneUtil.parse(phoneNumber, region);
							isValidNumber = phoneUtil.isValidNumber(number);
						}
					});
                    if(isValidNumber){
                        return null;
                    }else{
                        return {invalidPhone: true};
                    }
                }catch(e){
                    return {invalidPhone: true};
                }
            }else{
                return null;
            }
        };
    };

    static email = (control: FormControl) => {
		if(!control.value || control.value.trim() == ''){
			return null
		}
        //const EMAIL_REGEXP = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        //const EMAIL_REGEXP = ^[_a-z0-9-]+(\.[_a-z0-9-]+)*@[_a-z0-9-]+(\.[_a-z0-9-]+)*(\.[a-z]{2,32})$^
        const EMAIL_REGEXP = /^([a-zA-Z0-9_\-\.]+)@([a-zA-Z0-9_\-\.]+)\.([a-zA-Z]{2,5})$/;
        let v:string = control.value.trim();
        return EMAIL_REGEXP.test(v) ? null : {invalidEmail: true};

    };

    static url = (control: FormControl) => {
        const URL_REGEXP = /^(?:(?:(?:https?|ftp):)?\/\/)(?:\S+(?::\S*)?@)?(?:(?!(?:10|127)(?:\.\d{1,3}){3})(?!(?:169\.254|192\.168)(?:\.\d{1,3}){2})(?!172\.(?:1[6-9]|2\d|3[0-1])(?:\.\d{1,3}){2})(?:[1-9]\d?|1\d\d|2[01]\d|22[0-3])(?:\.(?:1?\d{1,2}|2[0-4]\d|25[0-5])){2}(?:\.(?:[1-9]\d?|1\d\d|2[0-4]\d|25[0-4]))|(?:(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)(?:\.(?:[a-z\u00a1-\uffff0-9]-*)*[a-z\u00a1-\uffff0-9]+)*(?:\.(?:[a-z\u00a1-\uffff]{2,})).?)(?::\d{2,5})?(?:[/?#]\S*)?$/i;
        let v:string = control.value.trim();
        if(v.length == 0){
            return null;
        }else{
            return URL_REGEXP.test(v) ? null : {invalidUrl: true};
        }
	};

	static identification = (control: FormControl) => {
		if(!control.value || control.value.trim() == ''){
			return null
		}
		let dataControl = control.value.trim();
		dataControl = dataControl.toUpperCase().replace(/\s/, '');
		let type = ValidatorsCustom.spainIdType(dataControl);
		let _retData = {identification: true};
		switch (type) {
            case 'dni':
                if(ValidatorsCustom.validDni(dataControl)) _retData = null;
            break;
            case 'nie':
				if(ValidatorsCustom.validNie(dataControl)) _retData = null;
            break;
            case 'cif':
				_retData = null;
				//if(ValidatorsCustom.validCif(dataControl)) _retData = null;
            break;
		}
		return _retData;
	}

	static spainIdType = (str: string) => {
		let DNI_REGEX = /^(\d{8})([A-Z])$/;
		let CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
		let NIE_REGEX = /^[XYZ]\d{7,8}[A-Z]$/;
        if ( str.match( DNI_REGEX ) ) {return 'dni';}
		if ( str.match( CIF_REGEX ) ) {return 'cif';}
		if ( str.match( NIE_REGEX ) ) {return 'nie';}
		return null;
	}

    static validDni = (code: string) => {
		var nif_letters = "TRWAGMYFPDXBNJZSQVHLCKE";
		var letter = nif_letters.charAt( parseInt( code, 10 ) % 23 );
		return letter == code.charAt(8);
	}

	static validNie = (code:string) => {
		let dni_prefix:any = code.charAt(0);
		switch (dni_prefix) {
			case 'X': dni_prefix = 0; break;
			case 'Y': dni_prefix = 1; break;
			case 'Z': dni_prefix = 2; break;
		}
		return ValidatorsCustom.validDni(dni_prefix + code.substr(1));
	}

	static validCif = (cif: string) => {
		let CIF_REGEX = /^([ABCDEFGHJKLMNPQRSUVW])(\d{7})([0-9A-J])$/;
		let match = cif.match( CIF_REGEX );
		let letter  = match[1],
			number  = match[2],
			control = match[3];
		let even_sum:any = 0;
		let odd_sum:any = 0;
		let n:any;
		for ( let i = 0; i < number.length; i++) {
			n = parseInt( number[i], 10 );
			// Odd positions (Even index equals to odd position. i=0 equals first position)
			if ( i % 2 === 0 ) {
				// Odd positions are multiplied first.
				n *= 2;
				// If the multiplication is bigger than 10 we need to adjust
				odd_sum += n < 10 ? n : n - 9;
			// Even positions
			// Just sum them
			} else {
				even_sum += n;
			}
			let control_digit:any = (10 - (even_sum + odd_sum).toString().substr(-1) );
			let control_letter:any = 'JABCDEFGHI'.substr( control_digit, 1 );
			// Control must be a digit
			if ( letter.match( /[ABEH]/ ) ) {
				return control == control_digit;
			// Control must be a letter
			} else if ( letter.match( /[KPQS]/ ) ) {
				return control == control_letter;
			// Can be either
			} else {
				return control == control_digit || control == control_letter;
			}
		}
	}

	static postalCode = (countryControl:string = 'ES'): ValidatorFn => {
        return (cpControl: AbstractControl): {[key: string]: boolean} => {
			if(cpControl.value && typeof(cpControl.value) != 'undefined'){
				let _cp = cpControl.value.trim();
				let _cpLength = _cp.length;
				if(countryControl === 'ES'){
					if(_cpLength === 2){
						_cp = _cp+"000";
						_cpLength = _cp.length;
					}
				}
				countryControl = countryControl.toUpperCase();
				if(_cpLength > 0){
					let _okCp = false;
					switch(countryControl){
						case 'US':
							_okCp = /^\d{5}([\-]?\d{4})?$/.test(_cp);
						break;
						case 'UK':
							_okCp = /^(GIR|[A-Z]\d[A-Z\d]??|[A-Z]{2}\d[A-Z\d]??)[ ]??(\d[A-Z]{2})$/.test(_cp);
						break;
						case 'CA':
							_okCp = /^([ABCEGHJKLMNPRSTVXY]\d[ABCEGHJKLMNPRSTVWXYZ])\ {0,1}(\d[ABCEGHJKLMNPRSTVWXYZ]\d)$/.test(_cp);
						break;
						case 'ES':
							switch(_cpLength){ case 5:_okCp = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(_cp);break;}
							switch(_cpLength){ case 2: _okCp = /^(?:0[1-9]|[1-4]\d|5[0-2])\d{3}$/.test(_cp); break;}
						break;
						case 'FR':
							_okCp = /^(F-)?((2[A|B])|[0-9]{2})[0-9]{3}$/.test(_cp);
						break;
						case 'IT':
							_okCp = /^(V-|I-)?[0-9]{5}$/.test(_cp);
						break;
						case 'AU':
							_okCp = /^(0[289][0-9]{2})|([1345689][0-9]{3})|(2[0-8][0-9]{2})|(290[0-9])|(291[0-4])|(7[0-4][0-9]{2})|(7[8-9][0-9]{2})$/.test(_cp);
						break;
						case 'NL':
							_okCp = /^[1-9][0-9]{3}\s?([a-zA-Z]{2})?$/.test(_cp);
						break;
						case 'DK':
							_okCp = /^([D-d][K-k])?( |-)?[1-9]{1}[0-9]{3}$/.test(_cp);
						break;
						case 'SE':
							_okCp = /^(s-|S-){0,1}[0-9]{3}\s?[0-9]{2}$/.test(_cp);
						break;
						case 'BE':
							_okCp = /^[1-9]{1}[0-9]{3}$/.test(_cp);
						break;
						case 'DE':
						case 'GR':
							switch(_cpLength){ case 5: _okCp = /^([1-9]{2}|[0-9][1-9]|[1-9][0-9])[0-9]{3}$/.test(_cp); break; }
						break;
						case 'PT':
							switch(_cpLength){
								case 8:
									if(_cp.indexOf('-',1) === 4){
										let _cpTemp = _cp.replace('-','');
										_cpTemp = _cpTemp.replace(/[^\d]/g, "");
										if(_cpTemp.length === 7){
											_okCp = true;
										}
									}
								break;
								case 7:
									let _cpTemp = _cp.replace(/[^\d]/g, "");
									if(_cpTemp.length === 7){
										_okCp = true;
									}
								break;
							}
							if(_okCp){
								//los numeros no deben ser todos iguales
								let _cpTxt = _cp.replace('-','');
								_cpTxt = _cpTxt.replace(/[^\d]/g, "");
								let _cpArray = Array.from(_cpTxt);
								let _cpArrayRepeats = _cpArray.filter((item,index)=>{
									return (_cpArray.includes(item,index+1));
								});
								if(_cpArrayRepeats.length == _cpArray.length - 1){
									_okCp = false;
								}
							}
						break;
					}
					return (_okCp) ? null : {invalidCp: true};
				}else{
					return null;
				}
			}else{
				return null;
			}
        };
    };

}
