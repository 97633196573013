import { Injectable, Injector } from '@angular/core';
import { HttpEvent, HttpInterceptor, HttpHandler, HttpRequest, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError } from 'rxjs';
import { map, catchError } from 'rxjs/operators';
import { UserService } from './user.service';
import { environment } from '../../environments/environment';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
    providedIn: 'root'
})
@Injectable()
export class HttpInterceptorService implements HttpInterceptor {

    constructor(
		public translate:TranslateService,
        private injector: Injector,
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
		let currentLang = this.translate.getDefaultLang() || 'en';
        let user = this.injector.get<any>(UserService);
        let customHeader = {};
        if(!request.headers.has('X-File')) customHeader['Content-Type'] = 'application/json';
		if(user.getToken() != "")customHeader['X-Authorization'] = `Bearer ${user.getToken()}`;
		if(user.getClientId() != "")customHeader['X-Client'] = `${user.getClientId()}`;
		customHeader['X-App'] = environment.appBundle;
		customHeader['X-Version'] = environment.VERSION_APP_STRING;
		customHeader['Accept-Language'] = currentLang;
        request = request.clone({setHeaders: customHeader});
        return next.handle(request).pipe(
            map((event: HttpEvent<any>) => {
                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                if (error instanceof HttpErrorResponse) {
					switch(error.status){
						case 401: //Unauthorized
							user.logout();
						break;
					}
				}
                return throwError(error);
            })
        );
    }
}
