import { Component, EventEmitter, Input, OnDestroy, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SubSink } from 'subsink';
import { ThemeModel } from '@models/theme.model';
import { ThemeService } from '@services/theme.service';

@Component({
    selector: 'cm-header-modal',
    templateUrl: './header-modal.component.html',
    styleUrls: ['./header-modal.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderModalComponent implements OnInit, OnDestroy {
    private subs = new SubSink();

    @Input() title: string = '';
    @Input() backBtn: boolean = false;
    @Input() closeBtn: boolean = true;
    @Input() deleteBtn: boolean = false;

    @Output() backEvent = new EventEmitter();
    @Output() closeEvent = new EventEmitter();
    @Output() deleteEvent = new EventEmitter();

    public $theme: ThemeModel;

    constructor(private themeService: ThemeService) {
        this.$theme = null;
    }

    public backAction = () => {
        this.backEvent.emit(true);
    };
    public closeAction = () => {
        this.closeEvent.emit(true);
    };
    public deleteAction = () => {
        this.deleteEvent.emit(true);
    };

    ngOnInit() {
        this.subs.add(
            this.themeService.theme.subscribe((theme) => {
                if (theme) this.$theme = theme;
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
