import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

import { Network, ConnectionStatus } from '@capacitor/network';

@Injectable({
  	providedIn: 'root'
})
export class NetworkService {

	private status:BehaviorSubject<ConnectionStatus> = new BehaviorSubject({connected:true,connectionType:'unknown'});

  	constructor() {}

	async initNetworkEvents() {
		const _status = await Network.getStatus();
		console.log(_status);
		this.status.next(_status);
		Network.addListener('networkStatusChange', (status) => {
			console.log('CHANGE networkStatusChange', status);
			if(status.connected != this.status.value.connected){
				this.status.next(status);
			}
		});
	}

	public $onNetworkChange = ():Observable<ConnectionStatus> => {
		return this.status.asObservable();
	}

	public isOnline = () => {
		return this.status.value.connected;
	}

	public getConnectionType = () => {
		return this.status.value.connectionType;
	}



}
