import { ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation } from '@angular/core';
import { SubSink } from 'subsink';
import { ThemeModel } from '@models/theme.model';
import { UserModel } from '@models/user.model';
import { NotificationsService } from '@services/notifications.service';
import { ThemeService } from '@services/theme.service';
import { UserService } from '@services/user.service';

@Component({
    selector: 'cm-header',
    templateUrl: './header.component.html',
    styleUrls: ['./header.component.scss'],
    encapsulation: ViewEncapsulation.None,
})
export class HeaderComponent implements OnInit {
    private subs = new SubSink();

    @Input() title: string = '';
    @Input() backBtn: boolean = false;
    @Input() menuBtn: boolean = true;
    @Input() logoImage: boolean = false;
    @Input() addBtn: boolean = false;
    @Input() filterBtn: boolean = false;
    @Input() filterBtnActive: boolean = false;
    @Input() notificationBtn: boolean = false;
    @Input() closeBtn: boolean = false;
    @Input() issueBtn: boolean = false;
    @Input() logoutBtn: boolean = false;

    @Output() backEvent = new EventEmitter();
    @Output() addEvent = new EventEmitter();
    @Output() filterEvent = new EventEmitter();
    @Output() notificationEvent = new EventEmitter();
    @Output() closeEvent = new EventEmitter();
    @Output() issueEvent = new EventEmitter();
    @Output() logoutEvent = new EventEmitter();

    public $theme: ThemeModel;
    public $user: UserModel;

    public notificationsPending: number;

    constructor(
        private themeService: ThemeService,
        private cdr: ChangeDetectorRef,
        private user: UserService,
        private notificationsService: NotificationsService
    ) {
        this.notificationsPending = 0;
        this.$theme = null;
    }

    public backAction = () => {
        this.backEvent.emit(true);
    };
    public addAction = () => {
        this.addEvent.emit(true);
    };
    public filterAction = () => {
        this.filterEvent.emit(true);
    };
    public notificationAction = () => {
        this.notificationEvent.emit(true);
    };
    public closeAction = () => {
        this.closeEvent.emit(true);
    };
    public issueAction = () => {
        this.issueEvent.emit(true);
    };
    public logoutAction = () => {
        this.logoutEvent.emit(true);
    };

    ngOnInit() {
        this.subs.add(
            this.user.info.subscribe((data) => {
                this.$user = data;
                if (this.user.isAuthenticated()) {
                    this.notificationsService.getPending();
                }
            }),
            this.themeService.theme.subscribe((theme) => {
                if (theme) this.$theme = theme;
            }),
            this.notificationsService.pending.subscribe((data) => {
                this.notificationsPending = data;
                this.cdr.detectChanges();
            })
        );
    }

    ngOnDestroy() {
        this.subs.unsubscribe();
    }
}
